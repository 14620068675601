import React, { useState, useEffect, useRef } from 'react';
import { FaEye, FaSyncAlt, FaServer, FaRobot, FaBrain, FaLock, FaChartLine, FaUserSecret } from 'react-icons/fa';
import '../css/BehaviorMonitoring.css';

const BehaviorMonitoring = () => {
  const [monitoringData, setMonitoringData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modelStatus, setModelStatus] = useState({
    ddqn: 'active',
    ppo: 'active',
    status: 'training'
  });
  const [filter, setFilter] = useState('all');
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    fetchMonitoringData();
    
    const intervalId = setInterval(fetchMonitoringData, 60000);
    
    return () => clearInterval(intervalId);
  }, []);
  
  const fetchMonitoringData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch('/api/behavior-monitoring/data');
      
      if (!response.ok) {
        throw new Error('Failed to fetch monitoring data');
      }
      
      const data = await response.json();
      setMonitoringData(data);
      setLastUpdated(new Date());
      
      if (chartInstanceRef.current) {
        updateChart(data);
      }
      
    } catch (err) {
      console.error('Error fetching behavior monitoring data:', err);
      setError('Failed to load monitoring data. Please try again.');
      
      const mockData = generateMockData();
      setMonitoringData(mockData);
      
    } finally {
      setLoading(false);
    }
  };
  
  const generateMockData = () => {
    const users = [
      '67d54db0b0f1069da5a43e19',
      '81c237a4b3e2f569d4a31c28',
      '92f45dc0a7e3869bd1c53a17',
      '53b67ac9d2f1469e80a72d36'
    ];
    
    const methods = ['keyboard', 'mouse', 'touch', 'combined'];
    const encryptionAlgos = ['AES-256', 'RSA-2048', 'ECC', 'ChaCha20'];
    const dataMethodTypes = ['structured', 'unstructured', 'binary', 'mixed'];
    
    return Array.from({ length: 40 }, (_, i) => ({
      id: `mon_${i}`,
      userId: users[Math.floor(Math.random() * users.length)],
      method: methods[Math.floor(Math.random() * methods.length)],
      encryptionAlgo: encryptionAlgos[Math.floor(Math.random() * encryptionAlgos.length)],
      dataMethod: dataMethodTypes[Math.floor(Math.random() * dataMethodTypes.length)],
      dataMasked: Math.random() > 0.3,
      timestamp: new Date(Date.now() - Math.floor(Math.random() * 86400000 * 7)).toISOString(),
      typingSpeed: Math.floor(Math.random() * 250 + 50),
      mouseMovements: Math.floor(Math.random() * 1000 + 200),
      errorRate: (Math.random() * 0.15).toFixed(4),
      dwellTime: Math.floor(Math.random() * 2000 + 500),
      clickStream: Math.floor(Math.random() * 150 + 20),
      scrollBehavior: ['smooth', 'erratic', 'fast', 'slow'][Math.floor(Math.random() * 4)],
      pixelsPerSecond: Math.floor(Math.random() * 1000 + 200),
      navigationPattern: ['linear', 'random', 'depth-first', 'breadth-first'][Math.floor(Math.random() * 4)],
      anomalyScore: (Math.random() * 100).toFixed(2),
      mlModelPrediction: Math.random() > 0.8 ? 'anomalous' : 'normal',
      confidenceScore: (Math.random() * 100).toFixed(2),
      riskLevel: ['low', 'medium', 'high', 'critical'][Math.floor(Math.random() * 4)]
    }));
  };
  
  const updateChart = (data) => {
    console.log('Chart would be updated with new data', data.length);
  };
  
  const handleUserSelect = (userId) => {
    const filteredData = monitoringData.filter(item => item.userId === userId);
    setSelectedUser({
      userId,
      data: filteredData,
      metrics: calculateUserMetrics(filteredData)
    });
  };
  
  const calculateUserMetrics = (userData) => {
    if (!userData || userData.length === 0) return null;
    
    const avgTypingSpeed = userData.reduce((sum, item) => sum + item.typingSpeed, 0) / userData.length;
    const avgErrorRate = userData.reduce((sum, item) => sum + parseFloat(item.errorRate), 0) / userData.length;
    const avgDwellTime = userData.reduce((sum, item) => sum + item.dwellTime, 0) / userData.length;
    const avgAnomalyScore = userData.reduce((sum, item) => sum + parseFloat(item.anomalyScore), 0) / userData.length;
    
    const anomalies = userData.filter(item => item.mlModelPrediction === 'anomalous').length;
    
    return {
      avgTypingSpeed: avgTypingSpeed.toFixed(2),
      avgErrorRate: avgErrorRate.toFixed(4),
      avgDwellTime: avgDwellTime.toFixed(2),
      avgAnomalyScore: avgAnomalyScore.toFixed(2),
      totalRecords: userData.length,
      anomalies,
      anomalyPercentage: ((anomalies / userData.length) * 100).toFixed(2)
    };
  };
  
  // Filter data based on selected criteria
  const getFilteredData = () => {
    if (filter === 'all') return monitoringData;
    if (filter === 'anomalies') return monitoringData.filter(item => item.mlModelPrediction === 'anomalous');
    if (filter === 'high-risk') return monitoringData.filter(item => item.riskLevel === 'high' || item.riskLevel === 'critical');
    return monitoringData;
  };
  
  // Trigger a manual model training
  const handleTrainModel = async () => {
    try {
      setModelStatus(prev => ({ ...prev, status: 'training' }));
      
      const response = await fetch('/api/behavior-monitoring/train', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ force: true })
      });
      
      if (!response.ok) {
        throw new Error('Failed to start model training');
      }
      
      const data = await response.json();
      console.log('Training started:', data);
      
      // After a delay, set status back to active
      setTimeout(() => {
        setModelStatus(prev => ({ ...prev, status: 'active' }));
      }, 8000);
      
    } catch (err) {
      console.error('Error starting model training:', err);
      // For demo, still set back to active after delay
      setTimeout(() => {
        setModelStatus(prev => ({ ...prev, status: 'active' }));
      }, 8000);
    }
  };

  return (
    <div className="behavior-monitoring-container">
      <div className="monitoring-header">
        <h2><FaUserSecret /> Behavior Monitoring</h2>
        
        <div className="monitoring-controls">
          <div className="model-status">
            <span>ML Models:</span>
            <div className="model-status-badges">
              <span className={`status-badge ${modelStatus.ddqn}`}>
                DDQN {modelStatus.ddqn === 'active' ? 'Active' : 'Inactive'}
              </span>
              <span className={`status-badge ${modelStatus.ppo}`}>
                PPO {modelStatus.ppo === 'active' ? 'Active' : 'Inactive'}
              </span>
            </div>
          </div>
          
          <div className="filter-dropdown">
            <span>Filter:</span>
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option value="all">All Activities</option>
              <option value="anomalies">Anomalies Only</option>
              <option value="high-risk">High Risk</option>
            </select>
          </div>
          
          <button className="refresh-btn" onClick={fetchMonitoringData}>
            <FaSyncAlt /> Refresh
          </button>
          
          <button 
            className={`train-btn ${modelStatus.status === 'training' ? 'training' : ''}`}
            onClick={handleTrainModel}
            disabled={modelStatus.status === 'training'}
          >
            {modelStatus.status === 'training' ? (
              <>
                <FaBrain className="fa-spin" /> Training...
              </>
            ) : (
              <>
                <FaBrain /> Train Model
              </>
            )}
          </button>
        </div>
      </div>
      
      {loading && !monitoringData.length ? (
        <div className="loading-container">
          <FaSyncAlt className="fa-spin" />
          <p>Loading monitoring data...</p>
        </div>
      ) : error && !monitoringData.length ? (
        <div className="error-container">
          <p>{error}</p>
        </div>
      ) : (
        <div className="monitoring-content">
          <div className="monitoring-grid">
            <div className="user-list-card">
              <h3><FaEye /> Monitored Users</h3>
              <div className="user-list">
                {Array.from(new Set(monitoringData.map(item => item.userId))).map(userId => (
                  <div 
                    key={userId}
                    className={`user-item ${selectedUser?.userId === userId ? 'selected' : ''}`}
                    onClick={() => handleUserSelect(userId)}
                  >
                    <div className="user-identifier">{userId.substring(0, 8)}...</div>
                    <div className="user-stats">
                      <span className="stat-badge">
                        {monitoringData.filter(item => item.userId === userId).length} records
                      </span>
                      <span className={`risk-badge ${getHighestRisk(monitoringData.filter(item => item.userId === userId))}`}>
                        {getHighestRisk(monitoringData.filter(item => item.userId === userId))}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="data-visualization-card">
              <h3><FaChartLine /> Behavior Analysis</h3>
              {selectedUser ? (
                <div className="visualization-content">
                  <div className="user-metrics">
                    <div className="metric-item">
                      <span className="metric-label">Avg. Typing Speed</span>
                      <span className="metric-value">{selectedUser.metrics.avgTypingSpeed} cpm</span>
                    </div>
                    <div className="metric-item">
                      <span className="metric-label">Avg. Error Rate</span>
                      <span className="metric-value">{(selectedUser.metrics.avgErrorRate * 100).toFixed(2)}%</span>
                    </div>
                    <div className="metric-item">
                      <span className="metric-label">Anomalies</span>
                      <span className="metric-value">{selectedUser.metrics.anomalies} ({selectedUser.metrics.anomalyPercentage}%)</span>
                    </div>
                    <div className="metric-item">
                      <span className="metric-label">Avg. Anomaly Score</span>
                      <span className="metric-value">{selectedUser.metrics.avgAnomalyScore}</span>
                    </div>
                  </div>
                  
                  <div className="chart-container" ref={chartRef}>
                    <div className="canvas-placeholder">
                      <p>Behavior visualization would display here</p>
                      <p>User ID: {selectedUser.userId}</p>
                      <p>Records: {selectedUser.metrics.totalRecords}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="empty-selection">
                  <p>Select a user to view behavior analysis</p>
                </div>
              )}
            </div>
            
            <div className="activity-timeline-card">
              <h3><FaServer /> Activity Timeline</h3>
              <div className="activity-timeline">
                {getFilteredData().slice(0, 15).map((activity, index) => (
                  <div className="activity-item" key={activity.id || index}>
                    <div className="activity-timestamp">
                      {new Date(activity.timestamp).toLocaleString()}
                    </div>
                    <div className="activity-details">
                      <div className="activity-user">User: {activity.userId.substring(0, 8)}...</div>
                      <div className="activity-method">
                        Input: {activity.method} | Encryption: {activity.encryptionAlgo}
                      </div>
                      <div className="activity-stats">
                        <span className="stat-item">
                          Type: {activity.typingSpeed} cpm
                        </span>
                        <span className="stat-item">
                          Dwell: {activity.dwellTime} ms
                        </span>
                        <span className="stat-item">
                          Errors: {(activity.errorRate * 100).toFixed(2)}%
                        </span>
                      </div>
                      <div className="activity-prediction">
                        <span className={`prediction-badge ${activity.mlModelPrediction}`}>
                          {activity.mlModelPrediction} ({activity.confidenceScore}%)
                        </span>
                        <span className={`risk-badge ${activity.riskLevel}`}>
                          {activity.riskLevel}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="security-insights-card">
              <h3><FaLock /> Security Insights</h3>
              <div className="security-insights">
                <div className="insight-section">
                  <h4>Anomaly Detection Summary</h4>
                  <div className="anomaly-stats">
                    <div className="stat-block">
                      <span className="stat-number">
                        {monitoringData.filter(item => item.mlModelPrediction === 'anomalous').length}
                      </span>
                      <span className="stat-label">Anomalies Detected</span>
                    </div>
                    <div className="stat-block">
                      <span className="stat-number">
                        {(monitoringData.filter(item => item.mlModelPrediction === 'anomalous').length / monitoringData.length * 100).toFixed(1)}%
                      </span>
                      <span className="stat-label">Anomaly Rate</span>
                    </div>
                    <div className="stat-block">
                      <span className="stat-number">
                        {monitoringData.filter(item => item.riskLevel === 'high' || item.riskLevel === 'critical').length}
                      </span>
                      <span className="stat-label">High Risk Events</span>
                    </div>
                  </div>
                </div>
                
                <div className="insight-section">
                  <h4>Most Recent Anomalies</h4>
                  <div className="recent-anomalies">
                    {monitoringData
                      .filter(item => item.mlModelPrediction === 'anomalous')
                      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                      .slice(0, 3)
                      .map((anomaly, index) => (
                        <div className="anomaly-item" key={index}>
                          <div className="anomaly-time">
                            {new Date(anomaly.timestamp).toLocaleString()}
                          </div>
                          <div className="anomaly-user">
                            User: {anomaly.userId.substring(0, 8)}...
                          </div>
                          <div className="anomaly-detail">
                            <span className={`risk-badge ${anomaly.riskLevel}`}>
                              {anomaly.riskLevel}
                            </span>
                            <span className="anomaly-score">
                              Score: {anomaly.anomalyScore}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="monitoring-footer">
            <div className="last-updated">
              Last updated: {lastUpdated.toLocaleString()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function getHighestRisk(records) {
  const riskLevels = {
    'low': 1,
    'medium': 2,
    'high': 3,
    'critical': 4
  };
  
  let highestRisk = 'low';
  
  records.forEach(record => {
    if (riskLevels[record.riskLevel] > riskLevels[highestRisk]) {
      highestRisk = record.riskLevel;
    }
  });
  
  return highestRisk;
}

export default BehaviorMonitoring; 