import React, { useState } from 'react';
import '../css/Banking.css';
import { FaSave, FaTimesCircle, FaPlusCircle, FaTrash } from 'react-icons/fa';

const Banking = () => {
  const [formData, setFormData] = useState({
    accountName: '',
    accountNumber: '',
    bankName: '',
    routingNumber: '',
    accountType: 'checking',
    swiftCode: '',
    currency: 'USD'
  });

  const [accounts, setAccounts] = useState([]);
  const [formMode, setFormMode] = useState('add');
  const [editIndex, setEditIndex] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (formMode === 'add') {
      setAccounts([...accounts, formData]);
    } else {
      const updatedAccounts = [...accounts];
      updatedAccounts[editIndex] = formData;
      setAccounts(updatedAccounts);
    }
    
    setFormData({
      accountName: '',
      accountNumber: '',
      bankName: '',
      routingNumber: '',
      accountType: 'checking',
      swiftCode: '',
      currency: 'USD'
    });
    setShowForm(false);
    setFormMode('add');
  };

  const handleEdit = (index) => {
    setFormData(accounts[index]);
    setEditIndex(index);
    setFormMode('edit');
    setShowForm(true);
  };

  const handleDelete = (index) => {
    const updatedAccounts = accounts.filter((_, i) => i !== index);
    setAccounts(updatedAccounts);
  };

  const handleCancel = () => {
    setFormData({
      accountName: '',
      accountNumber: '',
      bankName: '',
      routingNumber: '',
      accountType: 'checking',
      swiftCode: '',
      currency: 'USD'
    });
    setShowForm(false);
    setFormMode('add');
  };

  return (
    <div className="banking-container">
      <div className="banking-header">
        <h2>Banking Information</h2>
        {!showForm && (
          <button 
            className="add-account-btn" 
            onClick={() => setShowForm(true)}
          >
            <FaPlusCircle /> Add Account
          </button>
        )}
      </div>

      {showForm ? (
        <div className="banking-form-container">
          <form className="banking-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="accountName">Account Name</label>
                <input
                  type="text"
                  id="accountName"
                  name="accountName"
                  value={formData.accountName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="accountNumber">Account Number</label>
                <input
                  type="text"
                  id="accountNumber"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="bankName">Bank Name</label>
                <input
                  type="text"
                  id="bankName"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="routingNumber">Routing Number</label>
                <input
                  type="text"
                  id="routingNumber"
                  name="routingNumber"
                  value={formData.routingNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="accountType">Account Type</label>
                <select
                  id="accountType"
                  name="accountType"
                  value={formData.accountType}
                  onChange={handleChange}
                >
                  <option value="checking">Checking</option>
                  <option value="savings">Savings</option>
                  <option value="business">Business</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="currency">Currency</label>
                <select
                  id="currency"
                  name="currency"
                  value={formData.currency}
                  onChange={handleChange}
                >
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="JPY">JPY</option>
                  <option value="CAD">CAD</option>
                  <option value="AUD">AUD</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="swiftCode">SWIFT/BIC Code</label>
                <input
                  type="text"
                  id="swiftCode"
                  name="swiftCode"
                  value={formData.swiftCode}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-actions">
              <button type="submit" className="save-btn">
                <FaSave /> {formMode === 'add' ? 'Save Account' : 'Update Account'}
              </button>
              <button type="button" className="cancel-btn" onClick={handleCancel}>
                <FaTimesCircle /> Cancel
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="accounts-list">
          {accounts.length === 0 ? (
            <div className="no-accounts">
              <p>No bank accounts added yet.</p>
            </div>
          ) : (
            accounts.map((account, index) => (
              <div className="account-card" key={index}>
                <div className="account-info">
                  <h3>{account.bankName}</h3>
                  <p><strong>Account Name:</strong> {account.accountName}</p>
                  <p><strong>Account Number:</strong> {account.accountNumber}</p>
                  <p><strong>Account Type:</strong> {account.accountType.charAt(0).toUpperCase() + account.accountType.slice(1)}</p>
                  <p><strong>Currency:</strong> {account.currency}</p>
                </div>
                <div className="account-actions">
                  <button className="edit-btn" onClick={() => handleEdit(index)}>Edit</button>
                  <button className="delete-btn" onClick={() => handleDelete(index)}>
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Banking; 