import React, { useState, useEffect } from 'react';
import '../css/InsiderThreat.css';
import { 
  FaUserSecret, 
  FaExclamationTriangle, 
  FaShieldAlt, 
  FaRobot, 
  FaChartLine, 
  FaEye, 
  FaSync, 
  FaFilter, 
  FaBell, 
  FaBellSlash,
  FaUserClock,
  FaServer,
  FaFingerprint,
  FaDatabase
} from 'react-icons/fa';

const InsiderThreat = () => {
  const [threatData, setThreatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modelStatus, setModelStatus] = useState('running');
  const [filterCriteria, setFilterCriteria] = useState('all');
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState(null);

  useEffect(() => {
    const fetchThreatData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await fetch('/api/insider-threat/analysis');
        
        if (!response.ok) {
          throw new Error('Failed to fetch insider threat data');
        }
        
        const data = await response.json();
        setThreatData(data);
      } catch (err) {
        console.error('Error fetching insider threat data:', err);
        setError('Failed to fetch threat analysis data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchThreatData();
    
    const intervalId = setInterval(fetchThreatData, refreshInterval);
    
    return () => clearInterval(intervalId);
  }, [refreshInterval]);

  const calculateThreatScore = (user) => {
    const behaviorWeight = 0.4;
    const loginWeight = 0.3;
    const similarityWeight = 0.2;
    const anomalyWeight = 0.1;
    
    return (user.realtimeBehaviorScore * behaviorWeight) +
           ((10 - user.loginAttempts) * 10 * loginWeight) +
           (user.similarity * similarityWeight) +
           ((user.anomalyType === 'None' ? 0 : 100) * anomalyWeight);
  };

  // Handle actions on threats
  const handleMitigationAction = async (userId, action) => {
    try {
      const response = await fetch('/api/insider-threat/mitigate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          action
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to execute mitigation action');
      }
      
      // Update local state to reflect the action
      setThreatData(threatData.map(user => 
        user.userId === userId ? { ...user, mitigationAction: action } : user
      ));
      
      // Show success notification to user (omitted for brevity)
      
    } catch (error) {
      console.error('Error executing mitigation action:', error);
      // Show error notification to user (omitted for brevity)
    }
  };

  // Toggle notification for a user
  const toggleNotification = async (userId) => {
    try {
      // Find the current user
      const user = threatData.find(u => u.userId === userId);
      if (!user) return;
      
      // Toggle the notification status
      const newNotifyStatus = !user.notify;
      
      // Call the local API endpoint first
      const localResponse = await fetch('/api/insider-threat/notification-settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          notify: newNotifyStatus
        })
      });
      
      if (!localResponse.ok) {
        throw new Error('Failed to update notification settings locally');
      }
      
      // Then save to MongoDB
      const mongoResponse = await fetch('/api/insider-threat/save-to-mongo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          notify: newNotifyStatus,
          timestamp: new Date().toISOString(),
          userDetails: user
        })
      });
      
      if (!mongoResponse.ok) {
        console.error('Warning: Failed to save to MongoDB, but local update succeeded');
      }
      
      // Update the local state
      setThreatData(threatData.map(u => 
        u.userId === userId ? { ...u, notify: newNotifyStatus } : u
      ));
      
    } catch (error) {
      console.error('Error toggling notification:', error);
      // Show error toast or message to user
    }
  };

  const notifyAdminAboutUser = async () => {
    try {
      // Clear any previous messages and set saving state
      setSaveMessage(null);
      setIsSaving(true);
      
      if (!selectedUser) {
        setSaveMessage({ type: 'error', text: 'No user selected for notification' });
        setIsSaving(false);
        return;
      }
      
      console.log('Notifying admin about user:', selectedUser.userId);
      
      // Call the API endpoint to save only the selected user's data
      const response = await fetch('/api/insider-threat/notify-admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: selectedUser,
          timestamp: new Date().toISOString()
        })
      });
      
      const result = await response.json();
      console.log('Notification response:', result);
      
      if (!response.ok) {
        throw new Error(result.error || 'Failed to notify admin');
      }
      
      // Set success message
      setSaveMessage({ type: 'success', text: 'Admin notified successfully!' });
      
    } catch (error) {
      console.error('Error notifying admin:', error);
      setSaveMessage({ type: 'error', text: `Error: ${error.message}` });
    } finally {
      setIsSaving(false);
    }
  };

  const getFilteredData = () => {
    switch (filterCriteria) {
      case 'high-risk':
        return threatData.filter(user => calculateThreatScore(user) > 70);
      case 'medium-risk':
        return threatData.filter(user => calculateThreatScore(user) > 40 && calculateThreatScore(user) <= 70);
      case 'low-risk':
        return threatData.filter(user => calculateThreatScore(user) <= 40);
      default:
        return threatData;
    }
  };

  return (
    <div className="insider-threat-container">
      <div className="threat-header">
        <h2><FaUserSecret /> Insider Threat Detection</h2>
        <div className="threat-controls">
          <div className="model-status">
            <span>ML Model Status:</span>
            <span className={`status-badge ${modelStatus}`}>
              {modelStatus === 'running' ? 'Active' : 'Inactive'}
            </span>
          </div>
          <div className="filter-dropdown">
            <FaFilter />
            <select 
              value={filterCriteria}
              onChange={(e) => setFilterCriteria(e.target.value)}
            >
              <option value="all">All Users</option>
              <option value="high-risk">High Risk</option>
              <option value="medium-risk">Medium Risk</option>
              <option value="low-risk">Low Risk</option>
            </select>
          </div>
          <div className="refresh-control">
            <FaSync />
            <select 
              value={refreshInterval}
              onChange={(e) => setRefreshInterval(Number(e.target.value))}
            >
              <option value="30000">30 Seconds</option>
              <option value="60000">1 Minute</option>
              <option value="300000">5 Minutes</option>
              <option value="600000">10 Minutes</option>
            </select>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading threat analysis data...</p>
        </div>
      ) : error ? (
        <div className="error-container">
          <FaExclamationTriangle />
          <p>{error}</p>
        </div>
      ) : (
        <div className="threat-data-container">
          <table className="threat-table">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Login Time</th>
                <th>Device</th>
                <th>Login Attempts</th>
                <th>Anomaly Type</th>
                <th>Behavior Score</th>
                <th>Risk Level</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {getFilteredData().map(user => {
                const threatScore = calculateThreatScore(user);
                const riskLevel = 
                  threatScore > 70 ? 'high' : 
                  threatScore > 40 ? 'medium' : 'low';
                
                return (
                  <tr key={user.userId} className={`risk-${riskLevel}`}>
                    <td>{user.userId}</td>
                    <td>{new Date(user.loginTime).toLocaleString()}</td>
                    <td>{user.usageDevice}</td>
                    <td>{user.loginAttempts}</td>
                    <td className={user.anomalyType !== 'None' ? 'anomaly' : ''}>
                      {user.anomalyType}
                    </td>
                    <td>
                      <div className="score-bar">
                        <div 
                          className="score-fill" 
                          style={{ width: `${user.realtimeBehaviorScore}%` }}
                        ></div>
                        <span>{user.realtimeBehaviorScore}%</span>
                      </div>
                    </td>
                    <td>
                      <span className={`risk-badge ${riskLevel}`}>
                        {riskLevel.charAt(0).toUpperCase() + riskLevel.slice(1)}
                      </span>
                    </td>
                    <td>
                      <div className="action-buttons">
                        <button 
                          className="view-details" 
                          onClick={() => setSelectedUser(user)}
                        >
                          <FaEye />
                        </button>
                        <button 
                          className="mitigate-button"
                          onClick={() => handleMitigationAction(user.userId, 'block')}
                        >
                          <FaShieldAlt />
                        </button>
                        <button 
                          className={`notify-button ${user.notify ? 'active' : ''}`}
                          onClick={() => toggleNotification(user.userId)}
                        >
                          {user.notify ? <FaBell /> : <FaBellSlash />}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {selectedUser && (
        <div className="user-detail-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h3>User Activity Details</h3>
              <button className="close-modal" onClick={() => setSelectedUser(null)}>×</button>
            </div>
            <div className="user-detail-content">
              <div className="user-overview">
                <div className="user-id">
                  <FaUserClock />
                  <span>{selectedUser.userId}</span>
                </div>
                <div className="risk-score">
                  <div 
                    className="risk-indicator" 
                    style={{ 
                      background: `conic-gradient(
                        ${calculateThreatScore(selectedUser) > 70 ? '#ff3b30' : 
                          calculateThreatScore(selectedUser) > 40 ? '#ff9500' : '#34c759'} 
                        ${calculateThreatScore(selectedUser) * 3.6}deg, 
                        #e0e0e5 ${calculateThreatScore(selectedUser) * 3.6}deg 360deg
                      )`
                    }}
                  >
                    <div className="risk-center">
                      <span>{Math.round(calculateThreatScore(selectedUser))}</span>
                    </div>
                  </div>
                  <span className="risk-label">Overall Risk Score</span>
                </div>
              </div>
              
              <div className="detail-sections">
                <div className="detail-section">
                  <h4>Login Information</h4>
                  <div className="detail-item">
                    <span className="detail-label">Login Time:</span>
                    <span className="detail-value">{new Date(selectedUser.loginTime).toLocaleString()}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Login Frequency:</span>
                    <span className="detail-value">{selectedUser.loginFrequency}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Login Attempts:</span>
                    <span className="detail-value">{selectedUser.loginAttempts}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Web Verification:</span>
                    <span className="detail-value">{selectedUser.webVerification ? 'Verified' : 'Not Verified'}</span>
                  </div>
                </div>
                
                <div className="detail-section">
                  <h4>Behavioral Analysis</h4>
                  <div className="detail-item">
                    <span className="detail-label">Historical Pattern:</span>
                    <span className="detail-value">{selectedUser.historicalPattern}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Scroll Behavior:</span>
                    <span className="detail-value">{selectedUser.scrollBehavior}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Navigation Action:</span>
                    <span className="detail-value">{selectedUser.navigationAction}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Device:</span>
                    <span className="detail-value">{selectedUser.usageDevice}</span>
                  </div>
                </div>
                
                <div className="detail-section">
                  <h4>Anomaly Detection</h4>
                  <div className="detail-item">
                    <span className="detail-label">Anomaly Type:</span>
                    <span className={`detail-value ${selectedUser.anomalyType !== 'None' ? 'anomaly-detected' : ''}`}>
                      {selectedUser.anomalyType}
                    </span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Similarity Score:</span>
                    <span className="detail-value">{selectedUser.similarity}%</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Behavior Score:</span>
                    <span className="detail-value">{selectedUser.realtimeBehaviorScore}%</span>
                  </div>
                </div>
              </div>
              
              <div className="action-section">
                <h4>Mitigation Controls</h4>
                <div className="notification-toggle">
                  <span>Notification Status:</span>
                  <button 
                    className={`toggle-button ${selectedUser.notify ? 'active' : ''}`}
                    onClick={() => toggleNotification(selectedUser.userId)}
                  >
                    {selectedUser.notify ? 'Notifications On' : 'Notifications Off'}
                  </button>
                </div>
                <div className="save-all-button">
                  <button 
                    className={`save-all-btn ${isSaving ? 'loading' : ''}`}
                    onClick={notifyAdminAboutUser}
                    disabled={isSaving}
                    title="Notify admin about this user"
                  >
                    {isSaving ? (
                      <>
                        <FaSync className="fa-spin" /> Notifying...
                      </>
                    ) : (
                      <>
                        <FaDatabase /> Notify Admin
                      </>
                    )}
                  </button>
                  
                  {saveMessage && (
                    <div className={`save-message ${saveMessage.type}`}>
                      {saveMessage.text}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InsiderThreat; 