import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/DeviceSession.css';
import { FaDesktop, FaMobile, FaTablet, FaExclamationTriangle, FaClock, FaChartLine, FaShieldAlt, FaSpinner } from 'react-icons/fa';

const DeviceSession = () => {
  const navigate = useNavigate();
  const [deviceData, setDeviceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [savingSession, setSavingSession] = useState(false);
  const [riskScore, setRiskScore] = useState(null);
  const [deviceType, setDeviceType] = useState('');
  const [anomalies, setAnomalies] = useState([]);
  const [userData, setUserData] = useState({
    
    failedLoginCount: 0,
    accessFrequency: 'N/A',
    loginConsistency: 0,
    mfaStatus: 0
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    
    const collectDeviceData = async () => {
      try {
       
        const screenResolution = `${window.screen.width}×${window.screen.height}`;
        const colorDepth = window.screen.colorDepth;
        
       
        const userAgent = navigator.userAgent;
        const browserData = detectBrowser(userAgent);
        
        
        const osData = detectOS(userAgent);
        
        
        const language = navigator.language || navigator.userLanguage;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezoneOffset = new Date().getTimezoneOffset();
        
        
        const doNotTrack = navigator.doNotTrack || 
                           window.doNotTrack || 
                           navigator.msDoNotTrack || 'Not available';
        
        
        const deviceMemory = navigator.deviceMemory || 'Not available';
        
        
        const hardwareConcurrency = navigator.hardwareConcurrency || 'Not available';
        
        
        const fonts = await detectFonts();
        
        
        const canvasFingerprint = generateCanvasFingerprint();
        
        
        const webglFingerprint = await generateWebGLFingerprint();
        
        
        const data = {
          screenResolution,
          colorDepth,
          browser: browserData.browser,
          browserVersion: browserData.version,
          os: osData.os,
          osVersion: osData.version,
          fonts,
          language,
          timezone,
          timezoneOffset,
          doNotTrack,
          deviceMemory,
          hardwareConcurrency,
          canvasFingerprint,
          webglFingerprint,
          touchSupport: 'ontouchstart' in window,
          cookiesEnabled: navigator.cookieEnabled,
          platform: navigator.platform,
        };
        
        setDeviceData(data);
        
        
        performMLAnalysis(data);
        
      } catch (error) {
        console.error('Error collecting device data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    collectDeviceData();
    
    
    fetchCurrentUserData();
  }, []);
  
  
  const detectBrowser = (userAgent) => {
    const browsers = [
      { name: 'Chrome', regex: /Chrome\/([0-9.]+)/ },
      { name: 'Firefox', regex: /Firefox\/([0-9.]+)/ },
      { name: 'Safari', regex: /Safari\/([0-9.]+).*Version\/([0-9.]+)/ },
      { name: 'Edge', regex: /Edg(e|)\/([0-9.]+)/ },
      { name: 'Opera', regex: /OPR\/([0-9.]+)/ },
      { name: 'IE', regex: /Trident\/.*rv:([0-9.]+)/ }
    ];
    
    for (const browser of browsers) {
      const match = userAgent.match(browser.regex);
      if (match) {
        return { 
          browser: browser.name, 
          version: browser.name === 'Safari' ? match[2] : match[1] 
        };
      }
    }
    
    return { browser: 'Unknown', version: 'Unknown' };
  };
  
  
  const detectOS = (userAgent) => {
    const operatingSystems = [
      { name: 'Windows', regex: /Windows NT ([0-9.]+)/ },
      { name: 'macOS', regex: /Mac OS X ([0-9._]+)/ },
      { name: 'iOS', regex: /iPhone OS ([0-9_]+)/ },
      { name: 'Android', regex: /Android ([0-9.]+)/ },
      { name: 'Linux', regex: /Linux/ }
    ];
    
    for (const os of operatingSystems) {
      const match = userAgent.match(os.regex);
      if (match) {
        let version = match[1] || 'Unknown';
        version = version.replace(/_/g, '.');
        return { os: os.name, version };
      }
    }
    
    return { os: 'Unknown', version: 'Unknown' };
  };
  

  const detectFonts = async () => {
    
    const fontList = [
      'Arial', 'Helvetica', 'Times New Roman', 'Times', 'Courier New',
      'Courier', 'Verdana', 'Georgia', 'Palatino', 'Garamond', 'Bookman',
      'Comic Sans MS', 'Trebuchet MS', 'Impact', 'Tahoma'
    ];
    
    
    const fontAvailable = (font) => {
      const testString = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()';
      
      
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '16px monospace';
      const baselineWidth = context.measureText(testString).width;
      
      
      context.font = `16px ${font}, monospace`;
      const testWidth = context.measureText(testString).width;
      
      
      return baselineWidth !== testWidth;
    };
    
    const detectedFonts = fontList.filter(fontAvailable);
    return detectedFonts.length > 0 ? detectedFonts : ['No fonts detected'];
  };
  
  
  const generateCanvasFingerprint = () => {
    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      ctx.textBaseline = 'top';
      ctx.font = '14px Arial';
      ctx.textBaseline = 'alphabetic';
      ctx.fillStyle = '#f60';
      ctx.fillRect(125, 1, 62, 20);
      ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
      ctx.fillText('iTrust360', 2, 15);
      ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
      ctx.fillText('iTrust360', 4, 17);
      
      const dataUrl = canvas.toDataURL();
      return hashCode(dataUrl).toString().substring(0, 12);
    } catch (e) {
      return 'Canvas API not supported';
    }
  };
  
  const generateWebGLFingerprint = async () => {
    try {
      const canvas = document.createElement('canvas');
      const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      
      if (!gl) {
        return 'WebGL not supported';
      }
      
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (!debugInfo) {
        return 'WebGL debug info not available';
      }
      
      const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      
      return hashCode(`${vendor}:${renderer}`).toString().substring(0, 8);
    } catch (e) {
      return 'WebGL fingerprinting failed';
    }
  };
  
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return Math.abs(hash);
  };
  
  const performMLAnalysis = (data) => {
    let score = 50;
    const foundAnomalies = [];
    
    if (
      (data.os === 'iOS' && data.browser === 'Chrome') ||
      (data.os === 'iOS' && data.browser === 'Firefox')
    ) {
      score += 20;
      foundAnomalies.push('Browser/OS mismatch: ' + data.browser + ' on ' + data.os);
    }
    
    if (data.os === 'iOS' || data.os === 'Android') {
      if (!data.touchSupport) {
        score += 25;
        foundAnomalies.push('Mobile OS without touch support');
      }
    }
    
    if (data.os === 'iOS' || data.os === 'Android') {
      if (parseInt(data.screenResolution.split('×')[0]) > 1920) {
        score += 15;
        foundAnomalies.push('Unusual screen resolution for mobile device');
      }
    }
    
    const browserTime = new Date().getHours();
    if (browserTime >= 2 && browserTime <= 5) {
      score += 10;
      foundAnomalies.push('Unusual access time (2-5 AM local time)');
    }
    
    if (data.doNotTrack !== 'Not available' && data.doNotTrack !== '0' && data.doNotTrack !== 'null') {
      score += 5;
      foundAnomalies.push('Do Not Track enabled');
    }
    
    let type = 'desktop';
    if (data.os === 'iOS' || data.os === 'Android') {
      if (parseInt(data.screenResolution.split('×')[0]) < 768) {
        type = 'mobile';
      } else {
        type = 'tablet';
      }
    }
    
    setRiskScore(score);
    setDeviceType(type);
    setAnomalies(foundAnomalies);
  };
  
  const renderRiskBadge = () => {
    let level, color;
    
    if (riskScore < 30) {
      level = 'Low';
      color = '#34c759';
    } else if (riskScore < 70) {
      level = 'Medium';
      color = '#ff9500';
    } else {
      level = 'High';
      color = '#ff3b30';
    }
    
    return (
      <div className="risk-badge" style={{ backgroundColor: color }}>
        {level} Risk
      </div>
    );
  };

  const renderDeviceIcon = () => {
    switch (deviceType) {
      case 'mobile':
        return <FaMobile size={24} />;
      case 'tablet':
        return <FaTablet size={24} />;
      default:
        return <FaDesktop size={24} />;
    }
  };

  const fetchCurrentUserData = async () => {
    try {
      setLoading(true);
      
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('Authentication required');
      }
      
      const response = await fetch('/api/users/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      
      const userData = await response.json();
      console.log('Raw user data from database:', userData);
      
      setUserData({
        failedLoginCount: userData.failedLoginCount || 0,
        accessFrequency: userData.accessFrequency || 'N/A',
        loginConsistency: userData.loginConsistency || 0,
        mfaStatus: userData.mfaStatus || 0,
        lastLoginTime: userData.lastLoginTime ? new Date(userData.lastLoginTime) : null,
        lastLogoutTime: userData.lastLogoutTime ? new Date(userData.lastLogoutTime) : null
      });
      
      console.log('User data loaded:', userData);
    } catch (error) {
      console.error('Error fetching current user data:', error);
      setError('Failed to load user security data');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      setSavingSession(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        console.error('No authentication token found');
        return;
      }
      
      const sessionData = {
        deviceType: deviceType || 'unknown',
        operatingSystem: deviceData.os || 'unknown',
        browser: deviceData.browser || 'unknown',
        screenResolution: deviceData.screenResolution || 'unknown',
        colorDepth: deviceData.colorDepth ? `${deviceData.colorDepth}-bit` : 'unknown',
        deviceMemory: deviceData.deviceMemory || 'unknown',
        cpuCores: deviceData.hardwareConcurrency || 'unknown',
        platform: deviceData.platform || 'unknown',
        touchSupport: deviceData.touchSupport ? 'Yes' : 'No',
        language: deviceData.language || 'unknown',
        timezone: deviceData.timezone || 'unknown',
        timezoneOffset: deviceData.timezoneOffset ? `${deviceData.timezoneOffset} minutes` : 'unknown',
        cookiesEnabled: deviceData.cookiesEnabled ? 'Yes' : 'No',
        doNotTrack: deviceData.doNotTrack || 'unknown',
        canvasFingerprint: deviceData.canvasFingerprint || 'unknown',
        webglFingerprint: deviceData.webglFingerprint || 'unknown',
        detectedFonts: deviceData.fonts || []
      };
      
      const response = await fetch('/api/sessions/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(sessionData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to save session data');
      }

      localStorage.removeItem('itrust360_behavior_tracking');
      localStorage.removeItem('itrust360_behavior_data');
      
      localStorage.removeItem('token');
      
      navigate('/');
      
    } catch (error) {
      console.error('Error during logout:', error);
      setError('Failed to save session data. Please try again.');
    } finally {
      setSavingSession(false);
    }
  };

  if (loading) {
    return <div className="loading-container">Collecting device information...</div>;
  }

  return (
    <div className="device-session-container">
      <div className="device-header">
        <div className="device-title">
          {renderDeviceIcon()}
          <h2>Device & Browser Analysis</h2>
        </div>
      </div>
      
      <div className="device-grid">
        <div className="device-card summary-card">
          <h3>Device Summary</h3>
          <div className="device-summary">
            <div className="summary-item">
              <span className="summary-label">Device Type:</span>
              <span className="summary-value">{deviceType.charAt(0).toUpperCase() + deviceType.slice(1)}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">Operating System:</span>
              <span className="summary-value">{deviceData.os} {deviceData.osVersion}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">Browser:</span>
              <span className="summary-value">{deviceData.browser} {deviceData.browserVersion}</span>
            </div>
            <div className="summary-item">
              <span className="summary-label">Screen Resolution:</span>
              <span className="summary-value">{deviceData.screenResolution}</span>
            </div>
          </div>
        </div>
        
        {anomalies.length > 0 && (
          <div className="device-card anomalies-card">
            <h3>Detected Anomalies</h3>
            <ul className="anomalies-list">
              {anomalies.map((anomaly, index) => (
                <li key={index}>
                  <FaExclamationTriangle className="anomaly-icon" />
                  {anomaly}
                </li>
              ))}
            </ul>
          </div>
        )}
        
      </div>
      
      <div className="data-section">
        <h3>Complete Device Fingerprint</h3>
        <div className="data-grid">
          <div className="data-card">
            <h4>Hardware</h4>
            <ul className="data-list">
              <li><strong>Screen Resolution:</strong> {deviceData.screenResolution}</li>
              <li><strong>Color Depth:</strong> {deviceData.colorDepth} bit</li>
              <li><strong>Device Memory:</strong> {deviceData.deviceMemory}</li>
              <li><strong>CPU Cores:</strong> {deviceData.hardwareConcurrency}</li>
              <li><strong>Platform:</strong> {deviceData.platform}</li>
              <li><strong>Touch Support:</strong> {deviceData.touchSupport ? 'Yes' : 'No'}</li>
            </ul>
          </div>
          
          <div className="data-card">
            <h4>Software</h4>
            <ul className="data-list">
              <li><strong>Browser:</strong> {deviceData.browser} {deviceData.browserVersion}</li>
              <li><strong>Operating System:</strong> {deviceData.os} {deviceData.osVersion}</li>
              <li><strong>Language:</strong> {deviceData.language}</li>
              <li><strong>Timezone:</strong> {deviceData.timezone}</li>
              <li><strong>Timezone Offset:</strong> {deviceData.timezoneOffset} minutes</li>
              <li><strong>Cookies Enabled:</strong> {deviceData.cookiesEnabled ? 'Yes' : 'No'}</li>
              <li><strong>Do Not Track:</strong> {deviceData.doNotTrack}</li>
            </ul>
          </div>
          
          <div className="data-card">
            <h4>Fingerprints</h4>
            <ul className="data-list">
              <li><strong>Canvas Fingerprint:</strong> {deviceData.canvasFingerprint}</li>
              <li><strong>WebGL Fingerprint:</strong> {deviceData.webglFingerprint}</li>
            </ul>
          </div>
          
          <div className="data-card">
            <h4>Detected Fonts</h4>
            <div className="fonts-container">
              {deviceData.fonts && deviceData.fonts.map((font, index) => (
                <span key={index} className="font-tag">{font}</span>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="security-metrics-container">
        <h3>Security Metrics</h3>
        
        <div className="metrics-grid">
          <div className="metric-card">
            <div className="metric-header">
              <div className="metric-icon failed-login">
                <FaExclamationTriangle />
              </div>
              <h4>Failed Logins</h4>
            </div>
            <div className="metric-content">
              <div className="metric-value">{userData.failedLoginCount}</div>
              <div className={`metric-status ${userData.failedLoginCount > 8 ? 'high-risk' : userData.failedLoginCount >= 4 ? 'medium-risk' : 'low-risk'}`}>
                {userData.failedLoginCount > 8 ? 'High Risk' : userData.failedLoginCount >= 4 ? 'Medium Risk' : 'Low Risk'}
              </div>
            </div>
          </div>
          
          <div className="metric-card">
            <div className="metric-header">
              <div className="metric-icon access-freq">
                <FaClock />
              </div>
              <h4>Access Frequency</h4>
            </div>
            <div className="metric-content">
              <div className="metric-value">{userData.accessFrequency}</div>
            </div>
            <div className="metric-detail">User access pattern</div>
          </div>
          
          <div className="metric-card">
            <div className="metric-header">
              <div className="metric-icon consistency">
                <FaChartLine />
              </div>
              <h4>Login Consistency</h4>
            </div>
            <div className="consistency-meter">
              <div 
                className="consistency-fill" 
                style={{width: `${userData.loginConsistency}%`}}
              ></div>
            </div>
            <div className="metric-content">
              <div className="metric-value">{userData.loginConsistency}</div>
              <div className={`metric-status ${userData.loginConsistency <= 3 ? 'low-risk' : userData.loginConsistency <= 6 ? 'medium-risk' : 'high-risk'}`}>
                {userData.loginConsistency <= 3 ? 'Low' : userData.loginConsistency <= 6 ? 'Average' : 'High Risk'}
              </div>
            </div>
          </div>
          
          <div className="metric-card">
            <div className="metric-header">
              <div className={`metric-icon mfa-status ${userData.mfaStatus ? 'enabled' : 'disabled'}`}>
                <FaShieldAlt />
              </div>
              <h4>MFA Status</h4>
            </div>
            <div className="metric-content">
              <div className="metric-value">{userData.mfaStatus ? 'Enabled' : 'Disabled'}</div>
              <div className={`metric-status ${userData.mfaStatus ? 'secure' : 'high-risk'}`}>
                {userData.mfaStatus ? 'Secure' : 'Vulnerable'}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="login-history-container">
        <h3>Login History</h3>
        
        <div className="login-history-card">
          <div className="login-history-item">
            <div className="history-label">Last Login Time:</div>
            <div className="history-value">
              {userData.lastLoginTime 
                ? userData.lastLoginTime.toLocaleString() 
                : 'Not available'}
            </div>
          </div>
          
          <div className="login-history-item">
            <div className="history-label">Last Logout Time:</div>
            <div className="history-value">
              {userData.lastLogoutTime 
                ? userData.lastLogoutTime.toLocaleString() 
                : 'Not available'}
            </div>
          </div>
          
          <div className="login-history-item">
            <div className="history-label">Session Duration:</div>
            <div className="history-value">
              {userData.lastLoginTime && userData.lastLogoutTime
                ? calculateSessionDuration(userData.lastLoginTime, userData.lastLogoutTime)
                : 'Not available'}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="logout-section">
        <button className="logout-button" onClick={handleLogout} disabled={savingSession}>
          {savingSession ? (
            <>
              <FaSpinner className="spinner-icon" /> Saving Session Data...
            </>
          ) : (
            'Log Out & Save Session Data'
          )}
        </button>
      </div> */}

      {error && (
        <div className="error-message">
          <FaExclamationTriangle /> {error}
        </div>
      )}
    </div>
  );
};

const getConsistencyColor = (score) => {
  if (score > 70) return '#34C759';
  if (score > 40) return '#FF9500';
  return '#FF3B30';
};

const calculateSessionDuration = (loginTime, logoutTime) => {
  if (logoutTime < loginTime) {
    [loginTime, logoutTime] = [logoutTime, loginTime];
  }
  
  const durationMs = logoutTime - loginTime;
  const minutes = Math.floor(durationMs / 60000);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  } else {
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }
};

export default DeviceSession; 