import React, { useState, useEffect } from 'react';
import { FaShieldAlt, FaSpinner, FaExclamationTriangle, FaCheckCircle, FaTimesCircle, FaFilter, FaSearch, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import '../css/AdminReviews.css';

const AdminReviews = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [processingId, setProcessingId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortConfig, setSortConfig] = useState({
    key: 'reviewDate',
    direction: 'desc'
  });

  useEffect(() => {
    forceRefresh();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [reviews, searchTerm, statusFilter, sortConfig]);

  const fetchReviews = async () => {
    try {
      setLoading(true);
      
      const fetchWithTimeout = async (url, options = {}, timeout = 10000) => {
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        
        const response = await fetch(url, {
          ...options,
          signal: controller.signal
        });
        
        clearTimeout(id);
        return response;
      };
      
      const response = await fetchWithTimeout('/api/admin-review');
      console.log('API response status:', response.status);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Received data:', data);
      
      if (data && data.length > 0) {
        setReviews(data);
        setFilteredReviews(data);
        setError(null);
      } else {
        console.log('No data found, trying to create sample data...');
        const sampleResponse = await fetch('/api/admin-review/create-sample');
        
        if (!sampleResponse.ok) {
          throw new Error(`Failed to create sample data: ${sampleResponse.status}`);
        }
      
        const retryResponse = await fetch('/api/admin-review');
        
        if (!retryResponse.ok) {
          throw new Error(`Error fetching after sample creation: ${retryResponse.status}`);
        }
        
        const retryData = await retryResponse.json();
        console.log('Retry received data:', retryData);
        
        setReviews(retryData);
        setFilteredReviews(retryData);
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.error('Request timed out');
        setError('Request timed out. Server might be overloaded.');
      } else {
        console.error('Error fetching admin reviews:', err);
        setError(`Failed to load admin reviews: ${err.message}`);
      }
      
      const hardcodedData = [
        {
          _id: "fallback1",
          userId: "test-user-1",
          deviceSessionScore: 75.4,
          behavioralScore: 62.8,
          historicalRiskScore: 48.2,
          systemLogsScore: 82.1,
          finalRiskScore: 68.6,
          riskLevel: "high",
          accessLevel: "Admin Review",
          reviewStatus: "pending",
          reviewDate: new Date().toISOString(),
          createdAt: new Date().toISOString()
        },
        {
          _id: "fallback2",
          userId: "test-user-2",
          deviceSessionScore: 45.2,
          behavioralScore: 52.8,
          historicalRiskScore: 38.2,
          systemLogsScore: 42.1,
          finalRiskScore: 44.6,
          riskLevel: "medium",
          accessLevel: "Admin Review",
          reviewStatus: "pending",
          reviewDate: new Date(Date.now() - 86400000).toISOString(),
          createdAt: new Date(Date.now() - 86400000).toISOString()
        }
      ];
      
      console.log('Using fallback data due to API error');
      setReviews(hardcodedData);
      setFilteredReviews(hardcodedData);
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = () => {
    let result = [...reviews];
    
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(review => 
        review.userId.toLowerCase().includes(term) ||
        review.riskLevel.toLowerCase().includes(term)
      );
    }
    
    if (statusFilter !== 'all') {
      result = result.filter(review => review.reviewStatus === statusFilter);
    }
    
    result.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    
    setFilteredReviews(result);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleStatusChange = async (reviewId, newStatus) => {
    try {
      setProcessingId(reviewId);
      
      const response = await fetch(`/api/admin-review/${reviewId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reviewStatus: newStatus })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const result = await response.json();
      
      setReviews(reviews.map(review => 
        review._id === reviewId ? { ...review, reviewStatus: newStatus } : review
      ));
      
      alert(`Review has been ${newStatus} successfully.`);
    } catch (err) {
      console.error(`Error ${newStatus} review:`, err);
      alert(`Failed to ${newStatus} review: ${err.message}`);
    } finally {
      setProcessingId(null);
    }
  };

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const forceRefresh = () => {
    setReviews([]);
    setFilteredReviews([]);
    fetchReviews();
  };

  if (loading) {
    return (
      <div className="admin-reviews-container loading">
        <FaSpinner className="spinner" />
        <p>Loading admin reviews...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-reviews-container error">
        <FaExclamationTriangle />
        <p>{error}</p>
        <button onClick={fetchReviews} className="retry-button">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="admin-reviews-container">
      <div className="admin-reviews-header">
        <div className="title-section">
          <h2><FaShieldAlt /> Admin Reviews</h2>
          <p>Manage user access review requests</p>
        </div>
        
        <div className="admin-reviews-filters">
          <div className="search-box">
            <FaSearch />
            <input 
              type="text" 
              placeholder="Search user ID or risk level..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="status-filter">
            <FaFilter />
            <select 
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">All Status</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
          
          <button 
            className="refresh-button"
            onClick={fetchReviews}
          >
            Refresh
          </button>
        </div>
      </div>
      
      <div className="reviews-summary">
        <div className="summary-item">
          <div className="summary-value">{reviews.length}</div>
          <div className="summary-label">Total Reviews</div>
        </div>
        <div className="summary-item pending">
          <div className="summary-value">
            {reviews.filter(r => r.reviewStatus === 'pending').length}
          </div>
          <div className="summary-label">Pending</div>
        </div>
        <div className="summary-item approved">
          <div className="summary-value">
            {reviews.filter(r => r.reviewStatus === 'approved').length}
          </div>
          <div className="summary-label">Approved</div>
        </div>
        <div className="summary-item rejected">
          <div className="summary-value">
            {reviews.filter(r => r.reviewStatus === 'rejected').length}
          </div>
          <div className="summary-label">Rejected</div>
        </div>
      </div>
      
      <div className="reviews-table-container">
        {filteredReviews.length === 0 ? (
          <div className="no-reviews">
            <p>No reviews found matching your criteria</p>
          </div>
        ) : (
          <table className="admin-reviews-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('userId')}>
                  User ID {getSortIcon('userId')}
                </th>
                <th onClick={() => handleSort('finalRiskScore')}>
                  Risk Score {getSortIcon('finalRiskScore')}
                </th>
                <th onClick={() => handleSort('riskLevel')}>
                  Risk Level {getSortIcon('riskLevel')}
                </th>
                <th onClick={() => handleSort('accessLevel')}>
                  Access Level {getSortIcon('accessLevel')}
                </th>
                <th onClick={() => handleSort('reviewStatus')}>
                  Status {getSortIcon('reviewStatus')}
                </th>
                <th onClick={() => handleSort('reviewDate')}>
                  Date {getSortIcon('reviewDate')}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredReviews.map(review => (
                <tr key={review._id} className={review.reviewStatus}>
                  <td className="user-id">{review.userId}</td>
                  <td className="risk-score">
                    <span className={`score-value ${review.riskLevel}`}>
                      {parseFloat(review.finalRiskScore).toFixed(1)}
                    </span>
                  </td>
                  <td>
                    <span className={`risk-badge ${review.riskLevel}`}>
                      {review.riskLevel.charAt(0).toUpperCase() + review.riskLevel.slice(1)}
                    </span>
                  </td>
                  <td>{review.accessLevel}</td>
                  <td>
                    <span className={`status-badge ${review.reviewStatus}`}>
                      {review.reviewStatus.charAt(0).toUpperCase() + review.reviewStatus.slice(1)}
                    </span>
                  </td>
                  <td className="date">{formatDate(review.reviewDate)}</td>
                  <td className="actions">
                    {review.reviewStatus === 'pending' ? (
                      <>
                        <button 
                          className="approve-button"
                          onClick={() => handleStatusChange(review._id, 'approved')}
                          disabled={processingId === review._id}
                        >
                          {processingId === review._id ? <FaSpinner className="spinner-small" /> : <FaCheckCircle />}
                          Approve
                        </button>
                        <button 
                          className="reject-button"
                          onClick={() => handleStatusChange(review._id, 'rejected')}
                          disabled={processingId === review._id}
                        >
                          {processingId === review._id ? <FaSpinner className="spinner-small" /> : <FaTimesCircle />}
                          Reject
                        </button>
                      </>
                    ) : (
                      <span className="action-taken">
                        {review.reviewStatus === 'approved' ? 'Approved' : 'Rejected'}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AdminReviews; 