import React, { useState, useEffect } from 'react';
import '../css/PredictiveAnalytics.css';
import { 
  FaChartLine, FaSync, FaUserShield, FaExclamationTriangle, 
  FaFilter, FaSearch, FaDownload, FaUserCog, FaShieldAlt,
  FaChartBar, FaInfoCircle, FaBell, FaLock, FaCheck
} from 'react-icons/fa';

const PredictiveAnalytics = () => {
  const [predictiveData, setPredictiveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modelStatus, setModelStatus] = useState('running');
  const [filterCriteria, setFilterCriteria] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [recommendedActions, setRecommendedActions] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [dataSource, setDataSource] = useState('api');
  
  // Utility function to parse CSV data
  const parseCSV = (csvText) => {
    const lines = csvText.trim().split('\n');
    const headers = lines[0].split(',');
    
    return lines.slice(1).map(line => {
      const values = line.split(',');
      const entry = {};
      
      headers.forEach((header, index) => {
        if (header === 'anomalyDetected' || header === 'anomalyFlag') {
          entry[header] = values[index] === 'true';
        } else if (header === 'score' || header === 'historicalThreats' || 
                  header === 'predictionConfidence' || header === 'incidentCount' || 
                  header === 'userBehaviorScore' || header === 'realtimeTrafficKB') {
          entry[header] = parseFloat(values[index]);
        } else {
          entry[header] = values[index];
        }
      });
      
      return entry;
    });
  };
  
  
  
  const sampleData = [
    {
      userId: "7db49c59694bbb17a493",
      score: 82,
      historicalThreats: 5,
      anomalyDetected: true,
      type: "Authentication",
      predictionConfidence: 87.4,
      timestamp: "2024-05-15T13:48:00Z",
      threatType: "Credential Theft",
      actionableInsight: "Enforce 2FA for high-risk access",
      loginStatus: "active",
      adminNotified: true,
      incidentType: "authentication",
      incidentCount: 3,
      userBehaviorScore: 63.2,
      realtimeTrafficKB: 487,
      geoLocation: "Sri Lanka",
      anomalyFlag: true
    },
    {
      userId: "239dae11ca04a0068511",
      score: 43,
      historicalThreats: 1,
      anomalyDetected: false,
      type: "Access",
      predictionConfidence: 93.2,
      timestamp: "2024-05-15T14:25:00Z",
      threatType: "None",
      actionableInsight: "Monitor for unusual activity patterns",
      loginStatus: "active",
      adminNotified: false,
      incidentType: "none",
      incidentCount: 0,
      userBehaviorScore: 78.9,
      realtimeTrafficKB: 215,
      geoLocation: "Canada",
      anomalyFlag: false
    },
    {
      userId: "a1a4ea3778037da4d62e",
      score: 91,
      historicalThreats: 8,
      anomalyDetected: true,
      type: "Data",
      predictionConfidence: 78.6,
      timestamp: "2024-05-15T08:17:00Z",
      threatType: "Data Exfiltration",
      actionableInsight: "Block external file transfers immediately",
      loginStatus: "active",
      adminNotified: true,
      incidentType: "data_transfer",
      incidentCount: 6,
      userBehaviorScore: 42.5,
      realtimeTrafficKB: 892,
      geoLocation: "Germany",
      anomalyFlag: true
    }
  ];
  
  // Fetch predictions data from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null); // Clear any previous errors
        
        // First attempt to get data from the API
        try {
          const apiResponse = await fetch('/api/predictive-analytics/predictions');
          if (!apiResponse.ok) {
            throw new Error(`API responded with status: ${apiResponse.status}`);
          }
          
          const data = await apiResponse.json();
          
          if (data && data.length > 0) {
            setPredictiveData(data);
            setLastUpdated(new Date());
            setDataSource('api');
            setShowNotification(true);
            setNotificationMessage('Using real-time predictions from ML model');
            
            // Hide notification after 3 seconds
            setTimeout(() => setShowNotification(false), 3000);
            
            setLoading(false);
            return;
          }
        } catch (apiErr) {
          console.error('Could not fetch data from API:', apiErr);
          // Continue to fallbacks
        }
        
        // If API fails, try CSV file
        try {
          const response = await fetch('/data/predictive_analytics.csv');
          const csvText = await response.text();
          
          // Parse CSV text
          const parsedData = parseCSV(csvText);
          
          if (parsedData && parsedData.length > 0) {
            setPredictiveData(parsedData);
            setLastUpdated(new Date());
            setDataSource('csv');
            setShowNotification(true);
            setNotificationMessage('Using ML service)');
            
            // Hide notification after 3 seconds
            setTimeout(() => setShowNotification(false), 3000);
          } else {
            throw new Error("CSV data is empty or invalid");
          }
        } catch (err) {
          console.error('Error fetching CSV data:', err);
          
          // As a last resort, use embedded sample data
          setPredictiveData(sampleData);
          setLastUpdated(new Date());
          setDataSource('embedded');
          setShowNotification(true);
          setNotificationMessage('Using sample data - service unavailable');
          setTimeout(() => setShowNotification(false), 3000);
        }
      } catch (err) {
        setError('Failed to load predictive analytics data');
        console.error('Error in data fetching:', err);
        
        // Still set some data as fallback
        setPredictiveData(sampleData);
        setDataSource('embedded');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
    
    // Refresh data every 60 seconds
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);
  
  // Filter data based on search and filter criteria
  const filteredData = predictiveData.filter(item => {
    // Skip if no data
    if (!item) return false;
    
    // Apply search filter if present
    if (searchQuery && !item.userId?.toLowerCase().includes(searchQuery.toLowerCase())) {
      return false;
    }
    
    // Apply type filter based on active tab
    if (activeTab !== 'all' && item.type?.toLowerCase() !== activeTab.toLowerCase()) {
      return false;
    }
    
    // Apply risk filter
    switch(filterCriteria) {
      case 'critical':
        return getRiskLevel(item.score) === 'critical';
      case 'high':
        return getRiskLevel(item.score) === 'high' || getRiskLevel(item.score) === 'critical';
      case 'medium':
        return getRiskLevel(item.score) === 'medium';
      case 'low':
        return getRiskLevel(item.score) === 'low';
      case 'all':
      default:
        return true;
    }
  });
  
  const handleViewUser = (user) => {
    setSelectedUser(user);
    // Generate recommendations based on user data
    setRecommendedActions(generateRecommendations(user));
  };
  
  const handleRefresh = async () => {
    try {
      setLoading(true);
      
      // Use direct CSV access for refresh as well
      const response = await fetch('/data/predictive_analytics.csv');
      const csvText = await response.text();
      
      // Parse CSV text
      const lines = csvText.trim().split('\n');
      const headers = lines[0].split(',');
      
      const parsedData = lines.slice(1).map(line => {
        const values = line.split(',');
        const entry = {};
        
        headers.forEach((header, index) => {
          if (header === 'anomalyDetected' || header === 'anomalyFlag') {
            entry[header] = values[index] === 'true';
          } else if (header === 'score' || header === 'historicalThreats' || 
                    header === 'predictionConfidence' || header === 'incidentCount' || 
                    header === 'userBehaviorScore' || header === 'realtimeTrafficKB') {
            entry[header] = parseFloat(values[index]);
          } else {
            entry[header] = values[index];
          }
        });
        
        return entry;
      });
      
      setPredictiveData(parsedData);
      setLastUpdated(new Date());
      setLoading(false);
      
      showNotificationMessage("Data refreshed successfully!");
    } catch (err) {
      setError('Failed to refresh data');
      setLoading(false);
      console.error(err);
    }
  };
  
  const showNotificationMessage = (message) => {
    setNotificationMessage(message);
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };
  
  const handleApplyAction = (action) => {
    showNotificationMessage(`Action applied: ${action.title}`);
  };
  
  const handleDismissAction = (action) => {
    showNotificationMessage(`Action dismissed: ${action.title}`);
    // Remove the action from recommendations
    setRecommendedActions(recommendedActions.filter(rec => rec.id !== action.id));
  };
  
  // Generate recommendations based on user data
  const generateRecommendations = (user) => {
    if (!user) return [];
    
    const recommendations = [];
    const riskLevel = getRiskLevel(user.score);
    
    if (riskLevel === 'critical' || riskLevel === 'high') {
      recommendations.push({
        id: 1,
        title: 'Enable Multi-Factor Authentication',
        description: 'Enforce MFA for this user immediately to prevent unauthorized access attempts.',
        impact: 'high',
        applied: false
      });
      
      recommendations.push({
        id: 2,
        title: 'Restrict Access Privileges',
        description: 'Temporarily reduce access permissions until the risk is mitigated.',
        impact: 'medium',
        applied: false
      });
    }
    
    if (user.anomalyDetected) {
      recommendations.push({
        id: 3,
        title: 'Investigate Unusual Activity',
        description: 'Review recent login patterns and data access for signs of compromise.',
        impact: 'medium',
        applied: false
      });
    }
    
    if (user.historicalThreats > 2) {
      recommendations.push({
        id: 4,
        title: 'Security Awareness Training',
        description: 'Schedule targeted security training for this user.',
        impact: 'low',
        applied: false
      });
    }
    
    if (user.realtimeTrafficKB > 500) {
      recommendations.push({
        id: 5,
        title: 'Monitor Data Transfers',
        description: 'Set up alerts for large data transfers from this account.',
        impact: 'medium',
        applied: false
      });
    }
    
    return recommendations;
  };
  
  // Get risk distribution data
  const getRiskDistribution = () => {
    const distribution = {
      critical: 0,
      high: 0,
      medium: 0,
      low: 0
    };
    
    predictiveData.forEach(item => {
      if (!item) return;
      
      const risk = getRiskLevel(item.score);
      distribution[risk]++;
    });
    
    return distribution;
  };
  
  const riskDistribution = getRiskDistribution();
  
  // Get metrics data
  const getMetricsData = () => {
    if (!predictiveData.length) return {};
    
    const totalUsers = predictiveData.length;
    const highRiskCount = predictiveData.filter(item => item && item.score >= 60).length;
    const anomalyCount = predictiveData.filter(item => item && item.anomalyDetected).length;
    
    return {
      totalUsers,
      highRiskPercentage: Math.round((highRiskCount / totalUsers) * 100),
      anomalyPercentage: Math.round((anomalyCount / totalUsers) * 100)
    };
  };
  
  const metricsData = getMetricsData();
  
  // Render empty state with more helpful information
  const renderEmptyState = () => {
    return (
      <div className="empty-state">
        <FaInfoCircle className="empty-icon" />
        <h3>No matching data found</h3>
        <p>Try adjusting your filter or search criteria:</p>
        <ul>
          <li>Current risk filter: <strong>{filterCriteria}</strong></li>
          <li>Current type filter: <strong>{activeTab}</strong></li>
          {searchQuery && <li>Search term: <strong>"{searchQuery}"</strong></li>}
        </ul>
        <button 
          className="reset-filters-btn"
          onClick={() => {
            setFilterCriteria('all');
            setActiveTab('all');
            setSearchQuery('');
          }}
        >
          Reset All Filters
        </button>
      </div>
    );
  };
  
  return (
    <div className="predictive-analytics-container">
      {/* Notification toast */}
      {showNotification && (
        <div className="notification-toast">
          <FaCheck className="notification-icon" />
          <span>{notificationMessage}</span>
        </div>
      )}
      
      <div className="analytics-header">
        <div className="header-title">
          <FaChartLine className="header-icon" />
          <h2>Predictive Risk Analytics</h2>
          <div className="model-status">
            <span className={`status-indicator ${modelStatus}`}></span>
            <span className="status-text">Model: {modelStatus}</span>
          </div>
        </div>
        
        <button 
          className="refresh-btn"
          onClick={handleRefresh}
          disabled={loading}
        >
          <FaSync className={loading ? 'spinning' : ''} />
          {loading ? 'Refreshing...' : 'Refresh Data'}
        </button>
      </div>
      
      {error && (
        <div className="error-message">
          <FaExclamationTriangle />
          <span>{error}</span>
        </div>
      )}
      
      <div className="dashboard-metrics">
        <div className="metric-card">
          <div className="metric-icon">
            <FaUserShield />
          </div>
          <div className="metric-content">
            <h3>Total Users Analyzed</h3>
            <div className="metric-value">{metricsData.totalUsers || 0}</div>
          </div>
        </div>
        
        <div className="metric-card risk-card">
          <div className="metric-icon">
            <FaExclamationTriangle />
          </div>
          <div className="metric-content">
            <h3>High Risk Users</h3>
            <div className="metric-value">{metricsData.highRiskPercentage || 0}%</div>
          </div>
          <div className="metric-chart">
            <div className="risk-bar">
              <div 
                className="risk-fill" 
                style={{width: `${metricsData.highRiskPercentage || 0}%`}}
              ></div>
            </div>
          </div>
        </div>
        
        <div className="metric-card anomaly-card">
          <div className="metric-icon">
            <FaShieldAlt />
          </div>
          <div className="metric-content">
            <h3>Anomaly Detection</h3>
            <div className="metric-value">{metricsData.anomalyPercentage || 0}%</div>
          </div>
          <div className="metric-chart">
            <div className="anomaly-bar">
              <div 
                className="anomaly-fill" 
                style={{width: `${metricsData.anomalyPercentage || 0}%`}}
              ></div>
            </div>
          </div>
        </div>
        
        <div className="metric-card distribution-card">
          <div className="metric-icon">
            <FaChartBar />
          </div>
          <div className="metric-content">
            <h3>Risk Distribution</h3>
            <div className="risk-distribution">
              <div className="risk-level">
                <span className="level critical"></span>
                <span className="count">{riskDistribution.critical}</span>
              </div>
              <div className="risk-level">
                <span className="level high"></span>
                <span className="count">{riskDistribution.high}</span>
              </div>
              <div className="risk-level">
                <span className="level medium"></span>
                <span className="count">{riskDistribution.medium}</span>
              </div>
              <div className="risk-level">
                <span className="level low"></span>
                <span className="count">{riskDistribution.low}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="analytics-tabs">
        <button 
          className={`tab-btn ${activeTab === 'all' ? 'active' : ''}`}
          onClick={() => setActiveTab('all')}
        >
          All Types
        </button>
        <button 
          className={`tab-btn ${activeTab === 'Authentication' ? 'active' : ''}`}
          onClick={() => setActiveTab('Authentication')}
        >
          Authentication
        </button>
        <button 
          className={`tab-btn ${activeTab === 'Data' ? 'active' : ''}`}
          onClick={() => setActiveTab('Data')}
        >
          Data Access
        </button>
        <button 
          className={`tab-btn ${activeTab === 'Network' ? 'active' : ''}`}
          onClick={() => setActiveTab('Network')}
        >
          Network
        </button>
        <button 
          className={`tab-btn ${activeTab === 'Access' ? 'active' : ''}`}
          onClick={() => setActiveTab('Access')}
        >
          System Access
        </button>
      </div>
      
      <div className="dashboard-controls">
        <div className="search-filter">
          <div className="search-box">
            <FaSearch />
            <input 
              type="text"
              placeholder="Search by user ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          
          <div className="filter-dropdown">
            <label><FaFilter /> Filter by risk: </label>
            <select 
              value={filterCriteria}
              onChange={(e) => setFilterCriteria(e.target.value)}
            >
              <option value="all">All risks</option>
              <option value="critical">Critical only</option>
              <option value="high">High & Critical</option>
              <option value="medium">Medium only</option>
              <option value="low">Low only</option>
            </select>
          </div>
        </div>
        
        <button className="export-btn">
          <FaDownload />
          Export Data
        </button>
      </div>
      
      <div className="users-table-container">
        {loading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Loading user risk data...</p>
          </div>
        ) : filteredData.length === 0 ? (
          renderEmptyState()
        ) : (
          <table className="users-table">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Risk Score</th>
                <th>Type</th>
                <th>Threat</th>
                <th>Confidence</th>
                <th>Location</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index} className={`risk-level-${getRiskLevel(item.score)}`}>
                  <td className="user-id-cell">
                    {item.anomalyDetected && (
                      <FaBell className="anomaly-icon" title="Anomaly detected" />
                    )}
                    {item.userId}
                  </td>
                  <td>
                    <div className={`risk-badge ${getRiskLevel(item.score)}`}>
                      {getRiskLevel(item.score)}
                    </div>
                    <span className="risk-score">{item.score}</span>
                  </td>
                  <td>{item.type}</td>
                  <td>
                    {item.threatType === 'None' ? (
                      <span className="no-threat">None</span>
                    ) : (
                      <span className="threat-type">{item.threatType}</span>
                    )}
                  </td>
                  <td>
                    <div className="confidence-bar">
                      <div 
                        className="confidence-fill"
                        style={{width: `${item.predictionConfidence}%`}}
                      ></div>
                      <span className="confidence-text">{item.predictionConfidence?.toFixed(1)}%</span>
                    </div>
                  </td>
                  <td>{item.geoLocation}</td>
                  <td>
                    <button 
                      className="view-btn"
                      onClick={() => handleViewUser(item)}
                    >
                      <FaUserShield /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      
      {selectedUser && (
        <div className="user-prediction-details">
          <div className="detail-header">
            <div className="user-header-info">
              <h3>User Risk Profile</h3>
              <div className="user-id">{selectedUser.userId}</div>
              {selectedUser.anomalyDetected && (
                <div className="anomaly-badge">
                  <FaBell /> Anomaly Detected
                </div>
              )}
            </div>
            <button className="close-btn" onClick={() => setSelectedUser(null)}>×</button>
          </div>
          
          <div className="user-prediction-content">
            <div className="user-info">
              <div className="risk-summary">
                <div className={`risk-score-circle ${getRiskLevel(selectedUser.score)}`}>
                  <div className="score-value">{selectedUser.score}</div>
                  <div className="score-label">Risk Score</div>
                </div>
                <div className="risk-details">
                  <div className="risk-detail-item">
                    <span className="detail-label">Type:</span>
                    <span className="detail-value">{selectedUser.type}</span>
                  </div>
                  <div className="risk-detail-item">
                    <span className="detail-label">Threat:</span>
                    <span className="detail-value">{selectedUser.threatType || 'None'}</span>
                  </div>
                  <div className="risk-detail-item">
                    <span className="detail-label">Confidence:</span>
                    <span className="detail-value">{selectedUser.predictionConfidence?.toFixed(1)}%</span>
                  </div>
                </div>
              </div>
              
              <div className="user-metrics">
                <div className="metric-item">
                  <div className="icon-container">
                    <FaUserCog />
                  </div>
                  <div className="metric-info">
                    <div className="label">Behavior Score</div>
                    <div className="value">{selectedUser.userBehaviorScore}</div>
                  </div>
                </div>
                <div className="metric-item">
                  <div className="icon-container">
                    <FaExclamationTriangle />
                  </div>
                  <div className="metric-info">
                    <div className="label">Historical Threats</div>
                    <div className="value">{selectedUser.historicalThreats}</div>
                  </div>
                </div>
                <div className="metric-item">
                  <div className="icon-container">
                    <FaBell />
                  </div>
                  <div className="metric-info">
                    <div className="label">Incidents</div>
                    <div className="value">{selectedUser.incidentCount}</div>
                  </div>
                </div>
              </div>
              
              <div className="user-details">
                <h4>Additional Information</h4>
                <div className="details-grid">
                  <div className="detail-row">
                    <div className="detail-label">Location</div>
                    <div className="detail-value">{selectedUser.geoLocation}</div>
                  </div>
                  <div className="detail-row">
                    <div className="detail-label">Status</div>
                    <div className="detail-value">
                      <span className={`status-dot ${selectedUser.loginStatus === 'active' ? 'active' : 'inactive'}`}></span>
                      {selectedUser.loginStatus}
                    </div>
                  </div>
                  <div className="detail-row">
                    <div className="detail-label">Traffic</div>
                    <div className="detail-value">{selectedUser.realtimeTrafficKB} KB</div>
                  </div>
                  <div className="detail-row">
                    <div className="detail-label">Last Updated</div>
                    <div className="detail-value">{new Date(selectedUser.timestamp).toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="recommendations">
              <div className="recommendations-header">
                <h4>
                  <FaLock className="recommendations-icon" />
                  Recommended Security Actions
                </h4>
              </div>
              <div className="recommendations-list">
                {recommendedActions.length > 0 ? (
                  recommendedActions.map(action => (
                    <div key={action.id} className={`recommendation-item impact-${action.impact}`}>
                      <div className="recommendation-header">
                        <h5>{action.title}</h5>
                        <div className="impact-badge">{action.impact}</div>
                      </div>
                      <p className="recommendation-description">{action.description}</p>
                      <div className="recommendation-actions">
                        <button 
                          className="apply-btn" 
                          onClick={() => handleApplyAction(action)}
                        >
                          <FaCheck /> Apply Now
                        </button>
                        <button 
                          className="dismiss-btn"
                          onClick={() => handleDismissAction(action)}
                        >
                          Dismiss
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-recommendations">
                    <FaInfoCircle className="no-data-icon" />
                    <p>No recommendations available for this user</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className="analytics-footer">
        <div className="last-updated">
          <FaSync className="update-icon" />
          Last updated: {lastUpdated.toLocaleString()}
        </div>
      </div>
    </div>
  );
};

// Helper function to determine risk level based on score
function getRiskLevel(score) {
  if (score >= 80) return 'critical';
  if (score >= 60) return 'high';
  if (score >= 40) return 'medium';
  return 'low';
}

export default PredictiveAnalytics; 