import React, { useState, useEffect } from 'react';
import '../css/SessionAndMetadata.css';
import { FaClock, FaExclamationTriangle, FaCalendarCheck, FaUserClock, FaHistory, FaCalendarTimes, FaShieldAlt, FaGlobe } from 'react-icons/fa';

const SessionAndMetadata = () => {
  const [sessionData, setSessionData] = useState({
    currentSessionDuration: 0,
    loginTime: new Date(),
    failedTransactions: [],
    recentSessions: [
      {
        id: '5f89a3d2c7e4b01f9c8d6e7a',
        userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36',
        startTime: new Date(Date.now() - 86400000 * 2),
        endTime: new Date(Date.now() - 86400000 * 2 + 3600000), 
        ipAddress: '192.168.1.105',
        actions: 42,
        vpnDetected: false
      },
      {
        id: '5f89a3d2c7e4b01f9c8d6e7b',
        userAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7)',
        startTime: new Date(Date.now() - 86400000),
        endTime: new Date(Date.now() - 86400000 + 1800000),
        ipAddress: '192.168.1.107',
        actions: 23,
        vpnDetected: true
      },
      {
        id: '5f89a3d2c7e4b01f9c8d6e7c',
        userAgent: 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X)',
        startTime: new Date(Date.now() - 43200000), 
        endTime: new Date(Date.now() - 43200000 + 900000),
        ipAddress: '192.168.1.110',
        actions: 12,
        vpnDetected: false
      }
    ],
    activeUsers: 18,
    averageSessionTime: '24 minutes',
    vpnDetected: false,
    proxyDetected: false,
    detectionResults: {},
    isDetecting: true
  });

  useEffect(() => {
    detectVpnAndProxy();
  }, []);

  const detectVpnAndProxy = async () => {
    try {
      setSessionData(prev => ({ ...prev, isDetecting: true }));
      
      const detectionResults = {
        webRTC: { detected: false, details: null },
        timezone: { detected: false, details: null },
        headers: { detected: false, details: null },
        fingerprint: { detected: false, details: null },
        connectionTiming: { detected: false, details: null }
      };
      
      const webRTCResult = await detectWebRTCLeaks();
      detectionResults.webRTC = webRTCResult;
      
      const timezoneResult = detectTimezoneDiscrepancy();
      detectionResults.timezone = timezoneResult;
      
      const headersResult = detectProxyHeaders();
      detectionResults.headers = headersResult;
      
      const fingerprintResult = detectFingerprintInconsistencies();
      detectionResults.fingerprint = fingerprintResult;
      
      const timingResult = await analyzeConnectionTiming();
      detectionResults.connectionTiming = timingResult;
      
      const vpnDetected = webRTCResult.detected || 
                          timezoneResult.detected || 
                          fingerprintResult.detected ||
                          timingResult.detected;
      
      const proxyDetected = headersResult.detected;
      
      setSessionData(prev => ({
        ...prev,
        vpnDetected,
        proxyDetected,
        detectionResults,
        isDetecting: false
      }));
      
    } catch (error) {
      console.error('Error detecting VPN/Proxy:', error);
      setSessionData(prev => ({ ...prev, isDetecting: false }));
    }
  };

  const detectWebRTCLeaks = () => {
    return new Promise((resolve) => {
      const result = { detected: false, details: null };
      
      if (window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection) {
        const pc = new (window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection)({
          iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
        });
        
        const ipAddresses = [];
        
        pc.onicecandidate = (event) => {
          if (event.candidate) {
            // Extract IP from candidate string
            const ipMatch = /([0-9]{1,3}(\.[0-9]{1,3}){3})/.exec(event.candidate.candidate);
            if (ipMatch && ipMatch[1]) {
              const ip = ipMatch[1];
              if (ipAddresses.indexOf(ip) === -1) {
                ipAddresses.push(ip);
              }
            }
          } else {
            pc.close();
            
            if (ipAddresses.length > 1) {
              result.detected = true;
              result.details = {
                message: 'Multiple IP addresses detected via WebRTC',
                ipAddresses
              };
            }
            
            resolve(result);
          }
        };
        
        pc.createOffer()
          .then(offer => pc.setLocalDescription(offer))
          .catch(() => {
            pc.close();
            resolve(result);
          });
          
        setTimeout(() => {
          if (pc.iceConnectionState !== 'closed') {
            pc.close();
            resolve(result);
          }
        }, 1000);
      } else {
        resolve(result);
      }
    });
  };

  const detectTimezoneDiscrepancy = () => {
    const result = { detected: false, details: null };
    
    try {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
      const clientOffset = new Date().getTimezoneOffset();
      
      const expectedTimezone = 'America/New_York';
      const expectedOffset = -240;
      
      if (clientTimezone !== expectedTimezone || Math.abs(clientOffset - expectedOffset) > 60) {
        result.detected = true;
        result.details = {
          message: 'Timezone discrepancy detected',
          client: {
            timezone: clientTimezone,
            offset: clientOffset
          },
          expected: {
            timezone: expectedTimezone,
            offset: expectedOffset
          }
        };
      }
    } catch (error) {
      console.error('Error in timezone analysis:', error);
    }
    
    return result;
  };

  const detectProxyHeaders = () => {
    const result = { detected: false, details: null };
    
    const mockHeaders = {
      'x-forwarded-for': '203.0.113.195, 70.41.3.18, 150.172.238.178',
      'via': '1.1 vegur, 1.1 varnish, 1.1 squid'
    };
    
    const proxyHeaders = ['x-forwarded-for', 'forwarded', 'via', 'proxy-connection'];
    const foundHeaders = [];
    
    for (const header of proxyHeaders) {
      if (mockHeaders[header]) {
        foundHeaders.push({
          name: header,
          value: mockHeaders[header]
        });
      }
    }
    
    if (foundHeaders.length > 0) {
      result.detected = true;
      result.details = {
        message: 'Proxy headers detected',
        headers: foundHeaders
      };
    }
    
    return result;
  };

  const detectFingerprintInconsistencies = () => {
    const result = { detected: false, details: null };
    const inconsistencies = [];
    
    const canvasCheck = checkCanvasFingerprint();
    if (canvasCheck.inconsistent) {
      inconsistencies.push(canvasCheck);
    }
    
    const webglCheck = checkWebGLFingerprint();
    if (webglCheck.inconsistent) {
      inconsistencies.push(webglCheck);
    }
    
    const fontCheck = checkFontInconsistencies();
    if (fontCheck.inconsistent) {
      inconsistencies.push(fontCheck);
    }
    
    if (inconsistencies.length > 0) {
      result.detected = true;
      result.details = {
        message: 'Fingerprint inconsistencies detected',
        inconsistencies
      };
    }
    
    return result;
  };

  const checkCanvasFingerprint = () => {
    const result = { type: 'canvas', inconsistent: false, details: null };
    
    try {
      const canvas = document.createElement('canvas');
      canvas.width = 200;
      canvas.height = 50;
      
      const ctx = canvas.getContext('2d');
      if (!ctx) return result;
      
      ctx.textBaseline = 'top';
      ctx.font = '14px Arial';
      ctx.fillStyle = '#F60';
      ctx.fillRect(125, 1, 62, 20);
      ctx.fillStyle = '#069';
      ctx.fillText('iTrust360 Check', 2, 15);
      ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
      ctx.fillText('iTrust360 Check', 4, 17);
      
      const dataURL = canvas.toDataURL();
      
      let hash = 0;
      for (let i = 0; i < dataURL.length; i++) {
        hash = ((hash << 5) - hash) + dataURL.charCodeAt(i);
        hash |= 0; 
      }
      
      if (hash === 0 || !dataURL || dataURL.length < 100) {
        result.inconsistent = true;
        result.details = {
          message: 'Canvas fingerprint appears inconsistent',
          hash
        };
      }
    } catch (error) {
      console.error('Error in canvas fingerprinting check:', error);
    }
    
    return result;
  };

  const checkWebGLFingerprint = () => {
    const result = { type: 'webgl', inconsistent: false, details: null };
    
    try {
      const canvas = document.createElement('canvas');
      const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      
      if (!gl) return result;
      
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (!debugInfo) return result;
      
      const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      
      const suspiciousTerms = [
        'swiftshader', 'llvmpipe', 'virtualbox', 'vmware', 'virtual', 
        'software rasterizer', 'mesa', 'chromium', 'remote', 'indirect'
      ];
      
      const vendorRenderer = (vendor + ' ' + renderer).toLowerCase();
      const foundTerms = suspiciousTerms.filter(term => vendorRenderer.includes(term));
      
      if (foundTerms.length > 0) {
        result.inconsistent = true;
        result.details = {
          message: 'WebGL fingerprint suggests virtualization/emulation',
          vendor,
          renderer,
          suspiciousTerms: foundTerms
        };
      }
    } catch (error) {
      console.error('Error in WebGL fingerprinting check:', error);
    }
    
    return result;
  };

  const checkFontInconsistencies = () => {
    const result = { type: 'fonts', inconsistent: false, details: null };
  
    
    try {
      const fontFamilies = [
        'Arial', 'Courier New', 'Georgia', 'Times New Roman', 
        'Trebuchet MS', 'Verdana', 'Helvetica', 'Impact'
      ];
      
      const testString = 'mmmmmmmmmmlli';
      const testSize = '72px';
      const testDiv = document.createElement('div');
      document.body.appendChild(testDiv);
      
      const baseWidths = {};
      const inconsistentFonts = [];
      
      testDiv.style.fontFamily = 'monospace';
      testDiv.style.fontSize = testSize;
      testDiv.textContent = testString;
      const baselineWidth = testDiv.offsetWidth;
      
      for (const font of fontFamilies) {
        testDiv.style.fontFamily = `${font}, monospace`;
        const width = testDiv.offsetWidth;
        
        baseWidths[font] = width;
        
        if (width === baselineWidth) {
          inconsistentFonts.push(font);
        }
      }
      
      document.body.removeChild(testDiv);
      
      if (inconsistentFonts.length > fontFamilies.length / 2) {
        result.inconsistent = true;
        result.details = {
          message: 'Suspicious font rendering detected',
          inconsistentFonts
        };
      }
    } catch (error) {
      console.error('Error in font inconsistency check:', error);
    }
    
    return result;
  };

  const analyzeConnectionTiming = () => {
    return new Promise((resolve) => {
      const result = { detected: false, details: null };
      
      const startTime = performance.now();
      
      const img = new Image();
      
      img.onload = () => {
        const endTime = performance.now();
        const loadTime = endTime - startTime;

        if (loadTime > 300) {
          result.detected = true;
          result.details = {
            message: 'Unusual connection timing detected',
            loadTime: `${loadTime.toFixed(2)}ms`
          };
        }
        
        resolve(result);
      };
      
      img.onerror = () => {
        result.detected = true;
        result.details = {
          message: 'Resource loading error',
          error: 'Failed to load timing test resource'
        };
        
        resolve(result);
      };
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
      
      setTimeout(() => {
        if (!result.detected) {
          resolve(result);
        }
      }, 2000);
    });
  };

  useEffect(() => {
    const mockFailedTransactions = [
      {
        id: 'tx-3847583',
        timestamp: new Date(Date.now() - 1800000), 
        error: 'Payment gateway timeout',
        user: 'john.doe@example.com',
        amount: '$129.99',
        status: 'Failed'
      },
      {
        id: 'tx-3847432',
        timestamp: new Date(Date.now() - 3600000),
        error: 'Insufficient funds',
        user: 'sarah.smith@example.com',
        amount: '$49.99',
        status: 'Declined'
      },
      {
        id: 'tx-3847290',
        timestamp: new Date(Date.now() - 7200000),
        error: 'Card verification failed',
        user: 'mike.jones@example.com',
        amount: '$299.99',
        status: 'Rejected'
      }
    ];

    setSessionData(prev => ({
      ...prev,
      failedTransactions: mockFailedTransactions
    }));
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const currentDuration = Math.floor((Date.now() - sessionData.loginTime) / 1000);
      setSessionData(prev => ({
        ...prev,
        currentSessionDuration: currentDuration
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, [sessionData.loginTime]);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
    ].join(':');
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = seconds / 31536000;
  
    if (interval > 1) return Math.floor(interval) + " years ago";
    interval = seconds / 2592000;
    if (interval > 1) return Math.floor(interval) + " months ago";
    interval = seconds / 86400;
    if (interval > 1) return Math.floor(interval) + " days ago";
    interval = seconds / 3600;
    if (interval > 1) return Math.floor(interval) + " hours ago";
    interval = seconds / 60;
    if (interval > 1) return Math.floor(interval) + " minutes ago";
    return Math.floor(seconds) + " seconds ago";
  };

  const getBrowserName = (userAgent) => {
    if (userAgent.indexOf("Chrome") > -1) return "Chrome";
    if (userAgent.indexOf("Safari") > -1) return "Safari";
    if (userAgent.indexOf("Firefox") > -1) return "Firefox";
    if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) return "Internet Explorer";
    if (userAgent.indexOf("Edge") > -1) return "Edge";
    return "Unknown";
  };

  const getDeviceType = (userAgent) => {
    if (userAgent.indexOf("iPhone") > -1 || userAgent.indexOf("Android") > -1) return "Mobile";
    if (userAgent.indexOf("iPad") > -1 || userAgent.indexOf("Tablet") > -1) return "Tablet";
    return "Desktop";
  };

  return (
    <div className="session-metadata-container">
      <div className="session-header">
        <h2>Session & Metadata Analytics</h2>
      </div>
      
      <div className="session-grid">
        <div className="current-session-card">
          <div className="session-card-header">
            <FaClock className="session-icon" />
            <h3>Current Session</h3>
          </div>
          
          <div className="session-timer">
            {formatDuration(sessionData.currentSessionDuration)}
          </div>
          
          <div className="session-details">
            <div className="session-detail-item">
              <span className="detail-label">Login Time:</span>
              <span className="detail-value">{formatDate(sessionData.loginTime)}</span>
            </div>
            <div className="session-detail-item">
              <span className="detail-label">Last Login Time</span>
              <span className="detail-value">{formatDate(sessionData.loginTime)}</span>
            </div>
            
          
            <div className="session-detail-item">
              <span className="detail-label">Status:</span>
              <span className="detail-value status-active">Active</span>
            </div>
          </div>
        </div>

      </div>
      
      <div className="session-metrics">
        <div className="metric-card">
          <FaUserClock className="metric-icon" />
          <div className="metric-content">
            <h4>Active Users</h4>
            <div className="metric-value">72</div>
          </div>
        </div>
        
        <div className="metric-card">
          <FaClock className="metric-icon" />
          <div className="metric-content">
            <h4>Avg. Session Time</h4>
            <div className="metric-value">{sessionData.averageSessionTime}</div>
          </div>
        </div>
        
        <div className="metric-card">
          <FaExclamationTriangle className="metric-icon warning" />
          <div className="metric-content">
            <h4>Failed Transactions</h4>
            <div className="metric-value">{sessionData.failedTransactions.length}</div>
          </div>
        </div>
        
      </div>
      
      <div className="session-history">
        <div className="history-header">
          <h3>Recent Session History</h3>
        </div>
        
        <div className="history-list">
          {sessionData.recentSessions.map((session, index) => (
            <div key={index} className="history-item">
              <div className="history-icon">
                {session.endTime ? <FaCalendarCheck /> : <FaCalendarTimes />}
              </div>
              <div className="history-content">
                <div className="history-details">
                  <span className="history-browser">{getBrowserName(session.userAgent)}</span>
                  <span className="history-device">{getDeviceType(session.userAgent)}</span>
                  <span className="history-ip">{session.ipAddress}</span>
                  {session.vpnDetected && (
                    <span className="history-vpn">
                      <FaShieldAlt /> VPN
                    </span>
                  )}
                </div>
                <div className="history-time">
                  <span className="history-date">{formatDate(session.startTime)}</span>
                  <span className="history-duration">
                    Duration: {formatDuration(Math.floor((session.endTime - session.startTime) / 1000))}
                  </span>
                </div>
                <div className="history-actions">
                  <span className="actions-count">{session.actions} actions performed</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SessionAndMetadata; 