import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Dashboard.css';
import { FaHome, FaUserShield, FaClipboardList, FaBell, FaCog, FaSignOutAlt, FaBars } from 'react-icons/fa';

const UserDashboard = () => {
  const navigate = useNavigate();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  // Collect device information at logout time
  const collectDeviceInfo = () => {
    try {
      // Get device and browser information
      const userAgent = navigator.userAgent;
      const deviceType = /Mobi|Android|iPhone|iPad|iPod/i.test(userAgent) ? 'Mobile' : 'Desktop';
      
      // OS detection
      let operatingSystem = 'Unknown';
      if (/Windows/i.test(userAgent)) operatingSystem = 'Windows';
      else if (/Macintosh|Mac OS X/i.test(userAgent)) operatingSystem = 'macOS';
      else if (/Linux/i.test(userAgent)) operatingSystem = 'Linux';
      else if (/Android/i.test(userAgent)) operatingSystem = 'Android';
      else if (/iPhone|iPad|iPod/i.test(userAgent)) operatingSystem = 'iOS';
      
      // Browser detection
      let browser = 'Unknown';
      if (/Edge/i.test(userAgent)) browser = 'Microsoft Edge';
      else if (/Firefox/i.test(userAgent)) browser = 'Firefox';
      else if (/Chrome/i.test(userAgent)) browser = 'Chrome';
      else if (/Safari/i.test(userAgent)) browser = 'Safari';
      else if (/Opera|OPR/i.test(userAgent)) browser = 'Opera';
      else if (/MSIE|Trident/i.test(userAgent)) browser = 'Internet Explorer';
      
      // Canvas fingerprint
      let canvasFingerprint = 'Not available';
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 200;
        canvas.height = 50;
        
        ctx.textBaseline = 'top';
        ctx.font = '14px Arial';
        ctx.fillStyle = '#f60';
        ctx.fillRect(10, 10, 100, 30);
        ctx.fillStyle = '#069';
        ctx.fillText('Fingerprint', 10, 15);
        
        canvasFingerprint = canvas.toDataURL().slice(0, 20) + '...';
      } catch (e) {
        // Canvas not supported
      }

      // WebGL fingerprint
      let webglFingerprint = 'Not available';
      try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (gl) {
          const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
          if (debugInfo) {
            webglFingerprint = (gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || 'unknown').slice(0, 20) + '...';
          }
        }
      } catch (e) {
        // WebGL not supported
      }
      
      return {
        deviceType,
        operatingSystem,
        browser,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        colorDepth: `${window.screen.colorDepth}-bit`,
        deviceMemory: navigator.deviceMemory ? `${navigator.deviceMemory} GB` : 'Unknown',
        cpuCores: navigator.hardwareConcurrency || 'Unknown',
        platform: navigator.platform || 'Unknown',
        touchSupport: 'ontouchstart' in window ? 'Yes' : 'No',
        language: navigator.language || 'Unknown',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Unknown',
        timezoneOffset: new Date().getTimezoneOffset() + ' minutes',
        cookiesEnabled: navigator.cookieEnabled ? 'Yes' : 'No',
        doNotTrack: navigator.doNotTrack || 'Unknown',
        canvasFingerprint,
        webglFingerprint,
        detectedFonts: [] // Simplified version without font detection
      };
    } catch (error) {
      console.error('Error collecting device info:', error);
      return {
        deviceType: 'Unknown',
        operatingSystem: 'Unknown',
        browser: 'Unknown'
      };
    }
  };

  // Enhanced logout function
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (token) {
        // Collect device information
        const deviceInfo = collectDeviceInfo();
        
        console.log('Sending device info to server:', deviceInfo);
        
        // Save session data and wait for completion
        try {
          const response = await fetch('/api/sessions/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(deviceInfo)
          });
          
          if (response.ok) {
            console.log('Session data saved successfully');
            const result = await response.json();
            console.log('Server response:', result);
          } else {
            console.error('Failed to save session data:', await response.text());
          }
        } catch (saveError) {
          console.error('Error saving session data:', saveError);
        }
      }
      
      // Only proceed with logout after attempting to save
      console.log('Completing logout process');
      localStorage.removeItem('token');
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
      // Fallback to basic logout if there's an error
      localStorage.removeItem('token');
      navigate('/');
    }
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <div className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-header">
          <h2>iTrust360</h2>
          <button className="toggle-sidebar" onClick={toggleSidebar}>
            <FaBars />
          </button>
        </div>
        <div className="sidebar-content">
          <ul className="sidebar-menu">
            <li className="active">
              <FaHome />
              <span>Home</span>
            </li>
            <li>
              <FaUserShield />
              <span>My Security</span>
            </li>
            <li>
              <FaClipboardList />
              <span>Reports</span>
            </li>
            <li>
              <FaBell />
              <span>Notifications</span>
            </li>
            <li>
              <FaCog />
              <span>Settings</span>
            </li>
          </ul>
        </div>
        <div className="sidebar-footer">
          <button onClick={handleLogout} className="logout-sidebar-btn">
            <FaSignOutAlt />
            <span>Logout</span>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className={`main-content ${sidebarCollapsed ? 'expanded' : ''}`}>
        <header className="dashboard-header">
          <div className="header-left">
            {sidebarCollapsed && (
              <button className="toggle-sidebar" onClick={toggleSidebar}>
                <FaBars />
              </button>
            )}
            <h1>Welcome to iTrust360</h1>
          </div>
          <div className="header-right">
            <button className="logout-button" onClick={handleLogout}>Logout</button>
          </div>
        </header>
        
        <div className="dashboard-content">
          <div className="security-overview">
            <div className="security-score-card">
              <h2>Security Score</h2>
              <div className="score-circle">85%</div>
              <p>Good security status</p>
            </div>
            
            <div className="security-recommendations">
              <h2>Recommendations</h2>
              <ul className="recommendation-list">
                <li>Enable two-factor authentication</li>
                <li>Update your recovery email</li>
                <li>Complete your security profile</li>
              </ul>
            </div>
          </div>
          
          <div className="user-profile">
            <h2>My Profile</h2>
            <div className="profile-info">
              <div className="info-card">
                <p><strong>Email:</strong> user@example.com</p>
                <p><strong>Last Login:</strong> Today, 10:45 AM</p>
                <p><strong>Account Status:</strong> Active</p>
              </div>
            </div>
          </div>
          
          <div className="quick-actions">
            <h2>Quick Actions</h2>
            <div className="action-buttons">
              <button className="action-btn">
                <FaClipboardList />
                <span>View Reports</span>
              </button>
              <button className="action-btn">
                <FaUserShield />
                <span>Security Check</span>
              </button>
              <button className="action-btn">
                <FaCog />
                <span>Settings</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard; 