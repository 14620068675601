import React, { useState, useEffect, useRef } from 'react';
import '../css/Behavioral.css';
import { 
  FaKeyboard, 
  FaMouse, 
  FaClock, 
  FaArrowsAltV, 
  FaFingerprint, 
  FaPlay, 
  FaPause, 
  FaExclamationTriangle, 
  FaChartLine, 
  FaRegCheckCircle
} from 'react-icons/fa';

const TRACKING_STATE_KEY = 'itrust360_behavior_tracking';
const TRACKING_DATA_KEY = 'itrust360_behavior_data';

const Behavioral = () => {
  const [isTracking, setIsTracking] = useState(false);
  const [behavioralData, setBehavioralData] = useState({
    keystroke: {
      confidence: 0,
      pattern: [],
      dwellTimes: [],
      flightTimes: [],
      matches: false,
      score: 0
    },
    mouse: {
      confidence: 0,
      movements: [],
      clicks: [],
      velocity: [],
      matches: false,
      score: 0
    },
    dwell: {
      current: 0,
      average: 0,
      pattern: [],
      score: 0
    },
    scroll: {
      speed: 0,
      direction: 'none',
      pattern: [],
      score: 0
    },
    clickstream: {
      sequence: [],
      patternMatch: 0,
      anomalies: 0,
      score: 0
    },
    overallScore: 0,
    anomalyDetected: false
  });
  
  const [keyPressData, setKeyPressData] = useState([]);
  const [mouseMovementData, setMouseMovementData] = useState([]);
  const [scrollData, setScrollData] = useState([]);
  const [lastClickTime, setLastClickTime] = useState(Date.now());
  const [dwellTimeStart, setDwellTimeStart] = useState(Date.now());
  const [dwellTimesHistory, setDwellTimesHistory] = useState([]);
  
  const keystrokeMapRef = useRef(new Map());
  const mousePositionsRef = useRef([]);
  const simulationTimerRef = useRef(null);
  const canvasRef = useRef(null);
  
  useEffect(() => {
    const trackingState = localStorage.getItem(TRACKING_STATE_KEY);
    const trackingData = localStorage.getItem(TRACKING_DATA_KEY);
    
    if (trackingState === 'true') {
      setIsTracking(true);
    }
    
    if (trackingData) {
      try {
        const parsedData = JSON.parse(trackingData);
        setBehavioralData(parsedData);
      } catch (error) {
        console.error('Error parsing behavioral data from storage:', error);
      }
    }
  }, []);
  
  useEffect(() => {
    if (isTracking) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('click', handleClick);
      document.addEventListener('scroll', handleScroll);
      setDwellTimeStart(Date.now());
      
      startSimulation();
      
      localStorage.setItem(TRACKING_STATE_KEY, 'true');
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('click', handleClick);
      document.removeEventListener('scroll', handleScroll);
      
      if (simulationTimerRef.current) {
        clearInterval(simulationTimerRef.current);
      }
      
      localStorage.setItem(TRACKING_STATE_KEY, 'false');
    }
    
    return () => {
      if (simulationTimerRef.current) {
        clearInterval(simulationTimerRef.current);
      }
    };
  }, [isTracking]);
  
  useEffect(() => {
    if (isTracking) {
      localStorage.setItem(TRACKING_DATA_KEY, JSON.stringify(behavioralData));
    }
  }, [behavioralData, isTracking]);
  
  useEffect(() => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      const { width, height } = canvasRef.current.getBoundingClientRect();
      
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      
      context.clearRect(0, 0, width, height);
      
      mousePositionsRef.current.forEach(point => {
        const x = (point.x / window.innerWidth) * width;
        const y = (point.y / window.innerHeight) * height;
        
        context.beginPath();
        const gradient = context.createRadialGradient(x, y, 1, x, y, 15);
        gradient.addColorStop(0, 'rgba(0, 113, 227, 0.6)');
        gradient.addColorStop(1, 'rgba(0, 113, 227, 0)');
        
        context.fillStyle = gradient;
        context.arc(x, y, 15, 0, Math.PI * 2);
        context.fill();
      });
    }
  }, [mouseMovementData]);
  
  const startTracking = () => {
    setIsTracking(true);
  };
  
  const stopTracking = () => {
    setIsTracking(false);
  };
  
  const startSimulation = () => {
    simulationTimerRef.current = setInterval(() => {
      simulateUserBehavior();
    }, 3000);
  };
  
  const simulateUserBehavior = () => {
    // Simulate keystroke dynamics
    const newKeystrokeDwellTime = 120 + Math.random() * 80;
    const newKeystrokes = [...behavioralData.keystroke.dwellTimes];
    if (newKeystrokes.length > 10) newKeystrokes.shift();
    newKeystrokes.push(newKeystrokeDwellTime);
    
    const newFlightTime = 200 + Math.random() * 100;
    const newFlightTimes = [...behavioralData.keystroke.flightTimes];
    if (newFlightTimes.length > 10) newFlightTimes.shift();
    newFlightTimes.push(newFlightTime);
    
    // Simulate mouse movements
    const newMouseVelocity = 5 + Math.random() * 20;
    const newMouseVelocities = [...behavioralData.mouse.velocity];
    if (newMouseVelocities.length > 10) newMouseVelocities.shift();
    newMouseVelocities.push(newMouseVelocity);
    
    // Add a random point to mouse positions
    const x = Math.random() * window.innerWidth;
    const y = Math.random() * window.innerHeight;
    mousePositionsRef.current.push({ x, y, time: Date.now() });
    if (mousePositionsRef.current.length > 20) mousePositionsRef.current.shift();
    
    // Update mouse movement data for rendering
    const newMouseMovementData = [...mouseMovementData];
    if (newMouseMovementData.length > 10) newMouseMovementData.shift();
    newMouseMovementData.push({ velocity: newMouseVelocity, x, y });
    setMouseMovementData(newMouseMovementData);
    
    // Simulate scroll behavior
    const newScrollSpeed = 10 + Math.random() * 40;
    const newScrollDirection = Math.random() > 0.5 ? 'up' : 'down';
    const newScrollData = [...scrollData];
    if (newScrollData.length > 10) newScrollData.shift();
    newScrollData.push({ speed: newScrollSpeed, direction: newScrollDirection });
    setScrollData(newScrollData);
    
    // Simulate dwell time
    const newDwellTime = 1000 + Math.random() * 9000;
    const newDwellTimes = [...dwellTimesHistory];
    if (newDwellTimes.length > 10) newDwellTimes.shift();
    newDwellTimes.push(newDwellTime);
    setDwellTimesHistory(newDwellTimes);
    
    // Simulate click stream
    const targets = ['button', 'link', 'menu', 'form', 'nav', 'header', 'content'];
    const newClick = {
      target: targets[Math.floor(Math.random() * targets.length)],
      timestamp: Date.now(),
      interval: 1000 + Math.random() * 4000
    };
    
    const newSequence = [...behavioralData.clickstream.sequence];
    if (newSequence.length > 10) newSequence.shift();
    newSequence.push(newClick);
    
    const keystrokeConfidence = 60 + Math.random() * 40;
    const mouseConfidence = 55 + Math.random() * 40;
    const dwellScore = 65 + Math.random() * 35;
    const scrollScore = 70 + Math.random() * 30;
    const clickStreamMatch = 0.6 + Math.random() * 0.4;
    const clickStreamScore = clickStreamMatch * 100;
    
    const overallScore = (keystrokeConfidence + mouseConfidence + dwellScore + scrollScore + clickStreamScore) / 5;
    
    const anomalyDetected = Math.random() < 0.1;
    
    setBehavioralData({
      keystroke: {
        confidence: keystrokeConfidence,
        pattern: behavioralData.keystroke.pattern,
        dwellTimes: newKeystrokes,
        flightTimes: newFlightTimes,
        matches: keystrokeConfidence > 75,
        score: keystrokeConfidence
      },
      mouse: {
        confidence: mouseConfidence,
        movements: behavioralData.mouse.movements,
        clicks: behavioralData.mouse.clicks,
        velocity: newMouseVelocities,
        matches: mouseConfidence > 70,
        score: mouseConfidence
      },
      dwell: {
        current: newDwellTime,
        average: newDwellTimes.reduce((a, b) => a + b, 0) / newDwellTimes.length,
        pattern: behavioralData.dwell.pattern,
        score: dwellScore
      },
      scroll: {
        speed: newScrollSpeed,
        direction: newScrollDirection,
        pattern: behavioralData.scroll.pattern,
        score: scrollScore
      },
      clickstream: {
        sequence: newSequence,
        patternMatch: clickStreamMatch,
        anomalies: anomalyDetected ? behavioralData.clickstream.anomalies + 1 : behavioralData.clickstream.anomalies,
        score: clickStreamScore
      },
      overallScore: overallScore,
      anomalyDetected: anomalyDetected
    });
  };
  
  const handleKeyDown = (e) => {
    const time = Date.now();
    keystrokeMapRef.current.set(e.code, time);
  };
  
  const handleKeyUp = (e) => {
    const time = Date.now();
    const keyDownTime = keystrokeMapRef.current.get(e.code);
    
    if (keyDownTime) {
      const dwellTime = time - keyDownTime;
      
      // Update key press data
      const newKeyPressData = [...keyPressData];
      if (newKeyPressData.length > 10) newKeyPressData.shift();
      newKeyPressData.push({ key: e.code, dwellTime });
      setKeyPressData(newKeyPressData);
      
      // Remove from mapping
      keystrokeMapRef.current.delete(e.code);
    }
  };
  
  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const time = Date.now();
    
    if (mousePositionsRef.current.length > 0) {
      const lastPosition = mousePositionsRef.current[mousePositionsRef.current.length - 1];
      const deltaX = clientX - lastPosition.x;
      const deltaY = clientY - lastPosition.y;
      const deltaTime = time - lastPosition.time;
      
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const velocity = deltaTime > 0 ? distance / deltaTime * 1000 : 0; 
      
      if (distance > 5) {
        mousePositionsRef.current.push({ x: clientX, y: clientY, time });
        if (mousePositionsRef.current.length > 50) mousePositionsRef.current.shift();
        
        const newMouseMovement = [...mouseMovementData];
        if (newMouseMovement.length > 10) newMouseMovement.shift();
        newMouseMovement.push({ velocity, x: clientX, y: clientY });
        setMouseMovementData(newMouseMovement);
      }
    } else {
      mousePositionsRef.current.push({ x: clientX, y: clientY, time });
    }
  };
  
  const handleClick = (e) => {
    const time = Date.now();
    const interval = time - lastClickTime;
    setLastClickTime(time);
    
    const targetElement = e.target.tagName.toLowerCase();
    const targetId = e.target.id || 'unknown';
    const targetClass = e.target.className || 'unknown';
    
    let targetDescription;
    if (targetId !== 'unknown') {
      targetDescription = `${targetElement}#${targetId}`;
    } else if (targetClass !== 'unknown' && typeof targetClass === 'string') {
      targetDescription = `${targetElement}.${targetClass.split(' ')[0]}`;
    } else {
      targetDescription = targetElement;
    }
    
    const newClick = {
      target: targetDescription,
      timestamp: time,
      interval
    };
    
    const newSequence = [...behavioralData.clickstream.sequence];
    if (newSequence.length > 10) newSequence.shift();
    newSequence.push(newClick);
    
    setBehavioralData({
      ...behavioralData,
      clickstream: {
        ...behavioralData.clickstream,
        sequence: newSequence
      }
    });
  };
  
  const handleScroll = (e) => {
    const scrollDirection = window.scrollY > (scrollData.length > 0 ? scrollData[scrollData.length - 1].lastY : 0) ? 'down' : 'up';
    const lastScrollTime = scrollData.length > 0 ? scrollData[scrollData.length - 1].time : Date.now();
    const scrollSpeed = Math.abs(window.scrollY - (scrollData.length > 0 ? scrollData[scrollData.length - 1].lastY : 0)) / (Date.now() - lastScrollTime) * 1000;
    
    const newScrollData = [...scrollData];
    if (newScrollData.length > 10) newScrollData.shift();
    newScrollData.push({
      direction: scrollDirection,
      speed: scrollSpeed,
      time: Date.now(),
      lastY: window.scrollY
    });
    
    setScrollData(newScrollData);
    
    setBehavioralData({
      ...behavioralData,
      scroll: {
        ...behavioralData.scroll,
        speed: scrollSpeed,
        direction: scrollDirection
      }
    });
  };
  
  const formatMs = (ms) => {
    return (ms / 1000).toFixed(2) + 's';
  };
  
  // Get a color for the score ring
  const getScoreColor = (score) => {
    if (score >= 80) return '#34c759';
    if (score >= 60) return '#ff9500';
    return '#ff3b30';
  };

  const safeToFixed = (value, digits = 1) => {
    if (value === null || value === undefined) return '0.0';
    return Number(value).toFixed(digits);
  };

  return (
    <div className="behavioral-container">
      <div className="behavioral-header">
        <h2>Behavioral Analysis</h2>
        <div className="tracking-controls">
          {!isTracking ? (
            <button className="start-tracking" onClick={startTracking}>
              <FaPlay /> Start Tracking
            </button>
          ) : (
            <button className="stop-tracking" onClick={stopTracking}>
              <FaPause /> Stop Tracking
            </button>
          )}
        </div>
      </div>
      
      {isTracking && (
        <div className="tracking-status">
          <FaRegCheckCircle className="status-icon" />
          <span>Currently monitoring behavioral patterns across all dashboard pages</span>
        </div>
      )}
      
      <div className="overall-score-container">
        <div 
          className="score-ring" 
          style={{ 
            background: `conic-gradient(
              ${getScoreColor(behavioralData.overallScore)} 
              ${behavioralData.overallScore * 3.6}deg, 
              #e0e0e5 ${behavioralData.overallScore * 3.6}deg 360deg
            )`
          }}
        >
          <div className="score-center">
            <span className="score-value">{Math.round(behavioralData.overallScore)}</span>
          </div>
        </div>
        
        <h3 className="score-heading">Behavioral Authentication Score</h3>
        <p className="score-description">
          {behavioralData.overallScore >= 80 
            ? 'High confidence that the current user matches the expected behavior pattern.'
            : behavioralData.overallScore >= 60
              ? 'Medium confidence. Some behavioral patterns match but there are deviations.'
              : 'Low confidence. Significant deviations from expected behavior pattern.'}
        </p>
      </div>
      
      {behavioralData.anomalyDetected && (
        <div className="anomaly-alert">
          <FaExclamationTriangle />
          <div className="alert-content">
            <h3>Behavioral Anomaly Detected</h3>
            <p>The current user's behavior deviates significantly from established patterns. 
              This could indicate account compromise or unauthorized access.</p>
          </div>
        </div>
      )}
      
      <div className="behavioral-grid">
        <div className="behavioral-card keystroke-card">
          <div className="card-header">
            <FaKeyboard className="card-icon" />
            <h3>Keystroke Dynamics</h3>
          </div>
          
          <div className="key-confidence-meter">
            <div className="confidence-label">
              <span>Confidence Match:</span>
              <span className={behavioralData.keystroke.confidence >= 75 ? 'high-confidence' : 'low-confidence'}>
                {safeToFixed(behavioralData.keystroke.confidence)}%
              </span>
            </div>
            <div className="meter-bar">
              <div 
                className="meter-fill" 
                style={{ width: `${behavioralData.keystroke.confidence}%` }}
              ></div>
            </div>
          </div>
          
          <div className="keystroke-visualization">
            <div className="keystroke-metrics">
              <div className="metric-group">
                <h4>Dwell Times (ms)</h4>
                <div className="keystroke-chart">
                  {behavioralData.keystroke.dwellTimes.slice(-8).map((time, index) => (
                    <div className="chart-bar-container" key={index}>
                      <div 
                        className="chart-bar" 
                        style={{ 
                          height: `${Math.min(100, time / 2)}%`,
                          backgroundColor: '#0071e3'
                        }}
                      ></div>
                      <div className="chart-label">{index + 1}</div>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="metric-group">
                <h4>Flight Times (ms)</h4>
                <div className="keystroke-chart">
                  {behavioralData.keystroke.flightTimes.slice(-8).map((time, index) => (
                    <div className="chart-bar-container" key={index}>
                      <div 
                        className="chart-bar" 
                        style={{ 
                          height: `${Math.min(100, time / 5)}%`,
                          backgroundColor: '#ff9500'
                        }}
                      ></div>
                      <div className="chart-label">{index + 1}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="key-pattern-result">
              {behavioralData.keystroke.matches ? (
                <div className="pattern-match">
                  <FaRegCheckCircle className="match-icon" /> Typing pattern matches user profile
                </div>
              ) : (
                <div className="pattern-mismatch">
                  <FaExclamationTriangle className="mismatch-icon" /> Typing pattern does not match
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div className="behavioral-card mouse-card">
          <div className="card-header">
            <FaMouse className="card-icon" />
            <h3>Mouse Movement Analysis</h3>
          </div>
          
          <div className="key-confidence-meter">
            <div className="confidence-label">
              <span>Confidence Match:</span>
              <span className={behavioralData.mouse.confidence >= 70 ? 'high-confidence' : 'low-confidence'}>
                {safeToFixed(behavioralData.mouse.confidence)}%
              </span>
            </div>
            <div className="meter-bar">
              <div 
                className="meter-fill" 
                style={{ width: `${behavioralData.mouse.confidence}%` }}
              ></div>
            </div>
          </div>
          
          <div className="mouse-visualization">
            <div className="movement-map">
              <canvas ref={canvasRef} className="movement-canvas"></canvas>
              <div className="map-overlay">Mouse movement heatmap</div>
            </div>
            
            <div className="velocity-metrics">
              <h4>Movement Velocity</h4>
              <div className="velocity-chart">
                {mouseMovementData.slice(-10).map((data, index) => (
                  <div className="chart-bar-container" key={index}>
                    <div 
                      className="chart-bar" 
                      style={{ 
                        height: `${Math.min(100, data.velocity / 5)}%`,
                        backgroundColor: index === mouseMovementData.length - 1 ? '#ff9500' : '#0071e3'
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        
        <div className="behavioral-card dwell-card">
          <div className="card-header">
            <FaClock className="card-icon" />
            <h3>Dwell Time Analysis</h3>
          </div>
          
          <div className="dwell-visualization">
            <div className="dwell-metrics">
              <div className="metric-item">
                <div className="metric-label">Current Dwell Time:</div>
                <div className="metric-value">{behavioralData.dwell.current} ms</div>
              </div>
              
              <div className="metric-item">
                <div className="metric-label">Average Dwell Time:</div>
                <div className="metric-value">{Math.round(behavioralData.dwell.average)} ms</div>
              </div>
              
              <div className="metric-item">
                <div className="metric-label">Consistency Score:</div>
                <div className="metric-value">{safeToFixed(behavioralData.dwell.score)}%</div>
              </div>
            </div>
            
            <div className="dwell-chart">
              <h4>Recent Dwell Times</h4>
              <div className="chart-container">
                {dwellTimesHistory.slice(-10).map((time, index) => (
                  <div className="chart-bar-container" key={index}>
                    <div 
                      className="chart-bar" 
                      style={{
                        height: `${Math.min(100, time / 100)}%`,
                        backgroundColor: '#0071e3'
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        
        <div className="behavioral-card scroll-card">
          <div className="card-header">
            <FaArrowsAltV className="card-icon" />
            <h3>Scroll Behavior Analysis</h3>
          </div>
          
          <div className="scroll-visualization">
            <div className="scroll-metrics">
              <div className="metric-item">
                <div className="metric-label">Current Direction:</div>
                <div className="metric-value">{behavioralData.scroll.direction}</div>
              </div>
              
              <div className="metric-item">
                <div className="metric-label">Scroll Speed:</div>
                <div className="metric-value">{safeToFixed(behavioralData.scroll.speed)} px/s</div>
              </div>
              
              <div className="metric-item">
                <div className="metric-label">Pattern Match Score:</div>
                <div className="metric-value">{safeToFixed(behavioralData.scroll.score)}%</div>
              </div>
            </div>
            
            <div className="scroll-chart">
              <h4>Scroll Speed History</h4>
              <div className="chart-container">
                {scrollData.map((data, index) => (
                  <div className="chart-bar-container" key={index}>
                    <div 
                      className="chart-bar" 
                      style={{
                        height: `${Math.min(100, data.speed / 10)}%`,
                        backgroundColor: data.direction === 'down' ? '#0071e3' : '#ff9500'
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        
        <div className="behavioral-card clickstream-card">
          <div className="card-header">
            <FaFingerprint className="card-icon" />
            <h3>Clickstream Analysis</h3>
          </div>
          
          <div className="clickstream-visualization">
            <div className="clickstream-metrics">
              <div className="metric-item">
                <div className="metric-label">Pattern Match:</div>
                <div className="metric-value">{safeToFixed(behavioralData.clickstream.patternMatch * 100)}%</div>
              </div>
              
              <div className="metric-item">
                <div className="metric-label">Anomalies Detected:</div>
                <div className="metric-value">{behavioralData.clickstream.anomalies}</div>
              </div>
              
              <div className="metric-item">
                <div className="metric-label">Confidence Score:</div>
                <div className="metric-value">{safeToFixed(behavioralData.clickstream.score)}%</div>
              </div>
            </div>
            
            <div className="clickstream-sequence">
              <h4>Recent Click Sequence</h4>
              <div className="sequence-container">
                {behavioralData.clickstream.sequence.slice(-6).map((click, index) => (
                  <div className="sequence-item" key={index}>
                    <div className="sequence-element">{click.target}</div>
                    {index < behavioralData.clickstream.sequence.slice(-6).length - 1 && (
                      <div className="sequence-arrow">→</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            
            <div className="click-interval-chart">
              <h4>Click Intervals</h4>
              <div className="chart-container">
                {behavioralData.clickstream.sequence.slice(-10).map((click, index) => (
                  <div className="chart-bar-container" key={index}>
                    <div 
                      className="chart-bar" 
                      style={{
                        height: `${Math.min(100, click.interval / 100)}%`,
                        backgroundColor: '#0071e3'
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default Behavioral; 