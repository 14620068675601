import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Notification.css';
import {
  FaBell,
  FaUserSlash,
  FaEye,
  FaTrash,
  FaExclamationTriangle,
  FaSync,
  FaChevronDown,
  FaChevronUp,
  FaUserSecret,
  FaCalendarAlt,
  FaCheck,
  FaUserClock
} from 'react-icons/fa';

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedNotification, setExpandedNotification] = useState(null);
  const [processingAction, setProcessingAction] = useState(null);
  const navigate = useNavigate();

  // Fetch notifications when component mounts
  useEffect(() => {
    fetchNotifications();
  }, []);

  // Add this function for mock data when API isn't available
  const fetchMockNotifications = () => {
    console.log('Using mock notifications data');
    // Generate some mock notification data based on the expected format
    const mockData = [
      {
        _id: '1',
        userId: '90257782f7804a23bcc4b18f',
        notificationType: 'admin-alert',
        status: 'pending',
        createdAt: new Date().toISOString(),
        userData: {
          userId: '90257782f7804a23bcc4b18f',
          loginTime: '2025-02-01 17:59:00',
          historicalPattern: 'Inconsistent',
          loginFrequency: 'Daily',
          usageDevice: 'iPad',
          scrollBehavior: 'Fast',
          navigationAction: 'Unusual',
          loginAttempts: 9,
          webVerification: false,
          anomalyType: 'Excessive Failed Attempts',
          similarity: 60.9,
          realtimeBehaviorScore: 97.6,
          mitigationAction: 'Monitor',
          notify: true
        }
      },
      {
        _id: '2',
        userId: '445d142c2b8d4477ad0c8831',
        notificationType: 'system-alert',
        status: 'pending',
        createdAt: new Date().toISOString(),
        userData: {
          userId: '445d142c2b8d4477ad0c8831',
          loginTime: '2025-02-02 02:00:00',
          historicalPattern: 'Consistent',
          loginFrequency: 'Monthly',
          usageDevice: 'Windows Desktop',
          scrollBehavior: 'Fast',
          navigationAction: 'Unusual',
          loginAttempts: 4,
          webVerification: false,
          anomalyType: 'Device Mismatch',
          similarity: 67.5,
          realtimeBehaviorScore: 63.0,
          mitigationAction: 'Block',
          notify: true
        }
      }
    ];
    
    setNotifications(mockData);
    setLoading(false);
  };

  // Modify the fetchNotifications function to use mock data if API fails
  const fetchNotifications = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log('Fetching notifications from MongoDB...');
      
      // Try MongoDB first
      try {
        const mongoResponse = await fetch('/api/notifications');
        console.log('MongoDB response status:', mongoResponse.status);
        
        if (mongoResponse.ok) {
          const mongoData = await mongoResponse.json();
          console.log('Notifications data from MongoDB:', mongoData);
          
          if (mongoData && mongoData.data) {
            console.log(`Received ${mongoData.data.length} notifications from ${mongoData.database} database`);
            
            // Update the mapping to use mongoData.data instead of mongoData directly
            const formattedNotifications = mongoData.data.map(threat => ({
              _id: threat._id,
              userId: threat.userId || 'unknown',
              notificationType: threat.notificationType || 'system-alert',
              status: threat.status || 'pending',
              createdAt: threat.createdAt || new Date().toISOString(),
              userData: threat.userData || {
                userId: threat.userId || 'unknown',
                anomalyType: threat.anomalyType || 'Unknown',
                realtimeBehaviorScore: threat.behaviorScore || 0,
                // Map other fields as needed
              }
            }));
            
            setNotifications(formattedNotifications);
            setLoading(false);
            return;
          }
        }
        
        // If MongoDB fails, try file-based approach
        console.log('MongoDB data not available, trying file-based approach');
        const fileResponse = await fetch('/api/notifications-file');
        
        if (fileResponse.ok) {
          const fileData = await fileResponse.json();
          console.log('Notifications data from file:', fileData);
          
          if (Array.isArray(fileData) && fileData.length > 0) {
            setNotifications(fileData);
            setLoading(false);
            return;
          }
        }
        
        // If both fail, use mock data
        console.log('Both MongoDB and file-based approaches failed, using mock data');
        fetchMockNotifications();
      } catch (err) {
        console.log('Error in API calls, using mock data:', err);
        fetchMockNotifications();
      }
    } finally {
      setLoading(false);
    }
  };

  // Toggle expanded view for a notification
  const toggleExpand = (id) => {
    if (expandedNotification === id) {
      setExpandedNotification(null);
    } else {
      setExpandedNotification(id);
    }
  };

  // Handle blocking a user
  const handleBlockUser = async (userId, notificationId) => {
    try {
      setProcessingAction(notificationId);
      
      // Call API to block the user
      const response = await fetch('/api/insider-threat/block-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          userId,
          notificationId 
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to block user');
      }
      
      // Update notification status locally
      setNotifications(notifications.map(notification => 
        notification._id === notificationId 
          ? { ...notification, status: 'blocked', actionTaken: 'blocked' } 
          : notification
      ));
      
    } catch (err) {
      console.error('Error blocking user:', err);
      alert('Failed to block user. Please try again.');
    } finally {
      setProcessingAction(null);
    }
  };

  // Handle viewing a user's threat details
  const handleViewThreatDetails = (userId) => {
    // This could navigate to the InsiderThreat component with the user pre-selected
    navigate(`/admin/dashboard?view=insider-threat&userId=${userId}`);
  };

  // Handle dismissing a notification
  const handleDismissNotification = async (notificationId) => {
    try {
      setProcessingAction(notificationId);
      
      console.log('Dismissing notification with ID:', notificationId);
      
      // Log the request body for debugging
      const requestBody = { notificationId };
      console.log('Request body:', JSON.stringify(requestBody));
      
      const response = await fetch('/api/insider-threat/dismiss-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
      
      // Log the response status
      console.log('Delete response status:', response.status);
      
      // Check the content type to ensure we're handling JSON
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        // Handle non-JSON response
        const textResponse = await response.text();
        console.error('Received non-JSON response:', textResponse.substring(0, 150) + '...');
        throw new Error('Server returned invalid response format');
      }
      
      // Now parse JSON safely
      const responseData = await response.json();
      console.log('Delete response data:', responseData);
      
      if (!response.ok) {
        throw new Error(`Failed to dismiss notification: ${responseData.error || response.statusText}`);
      }
      
      // Show confirmation that it was deleted
      console.log(`Notification ${notificationId} successfully deleted`);
      
      // Remove from UI
      setNotifications(prevNotifications => 
        prevNotifications.filter(notification => notification._id !== notificationId)
      );
      
    } catch (error) {
      console.error('Error dismissing notification:', error);
      alert(`Failed to dismiss notification: ${error.message}`);
    } finally {
      setProcessingAction(null);
    }
  };

  // Format timestamp nicely
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  // Get priority class for notification styling
  const getPriorityClass = (notification) => {
    const userData = notification.userData;
    
    if (!userData) return 'priority-medium';
    
    if (userData.anomalyType && userData.anomalyType !== 'None') {
      return 'priority-high';
    }
    
    if (userData.loginAttempts > 3) {
      return 'priority-high';
    }
    
    if (userData.similarity < 75) {
      return 'priority-high';
    }
    
    if (userData.realtimeBehaviorScore < 80) {
      return 'priority-medium';
    }
    
    return 'priority-low';
  };

  if (loading) {
    return (
      <div className="notification-container">
        <div className="notification-header">
          <h2><FaBell /> Threat Notifications</h2>
        </div>
        <div className="loading-container">
          <FaSync className="fa-spin" />
          <p>Loading notifications...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="notification-container">
        <div className="notification-header">
          <h2><FaBell /> Threat Notifications</h2>
        </div>
        <div className="error-container">
          <FaExclamationTriangle />
          <p>{error}</p>
          <button onClick={fetchNotifications}>Retry</button>
        </div>
      </div>
    );
  }

  return (
    <div className="notification-container">
      <div className="notification-header">
        <h2><FaBell /> Threat Notifications</h2>
        <div className="notification-controls">
          <button className="refresh-button" onClick={fetchNotifications}>
            <FaSync /> Refresh
          </button>
        </div>
      </div>
      
      {notifications.length === 0 ? (
        <div className="no-notifications">
          <FaBell />
          <h3>No Notifications</h3>
          <p>When a user triggers an alert or admin notification, it will appear here.</p>
          <button className="refresh-button" onClick={fetchNotifications}>
            <FaSync /> Refresh
          </button>
        </div>
      ) : (
        <div className="notifications-list">
          {notifications.map(notification => (
            <div 
              key={notification._id} 
              className={`notification-item ${getPriorityClass(notification)} ${expandedNotification === notification._id ? 'expanded' : ''}`}
            >
              <div className="notification-header" onClick={() => toggleExpand(notification._id)}>
                <div className="notification-icon">
                  <FaUserSecret />
                </div>
                <div className="notification-summary">
                  <h3>Potential Threat: User {notification.userId}</h3>
                  <div className="notification-meta">
                    <span className="timestamp">
                      <FaCalendarAlt /> {formatTimestamp(notification.timestamp)}
                    </span>
                    <span className="notification-type">
                      {notification.notificationType === 'admin-alert' ? 'Admin Alert' : 'System Alert'}
                    </span>
                  </div>
                </div>
                <div className="notification-expand">
                  {expandedNotification === notification._id ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
              
              {expandedNotification === notification._id && (
                <div className="notification-details">
                  <div className="user-details">
                    <h4>User Details</h4>
                    {notification.userData && (
                      <>
                        <div className="detail-row">
                          <span className="detail-label">User ID:</span>
                          <span className="detail-value">{notification.userData.userId}</span>
                        </div>
                        <div className="detail-row">
                          <span className="detail-label">Login Pattern:</span>
                          <span className="detail-value">{notification.userData.historicalPattern}</span>
                        </div>
                        <div className="detail-row">
                          <span className="detail-label">Login Frequency:</span>
                          <span className="detail-value">{notification.userData.loginFrequency}</span>
                        </div>
                        <div className="detail-row">
                          <span className="detail-label">Anomaly Type:</span>
                          <span className={`detail-value ${notification.userData.anomalyType !== 'None' ? 'anomaly-highlight' : ''}`}>
                            {notification.userData.anomalyType || 'None'}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="detail-label">Device:</span>
                          <span className="detail-value">{notification.userData.usageDevice}</span>
                        </div>
                        <div className="detail-row">
                          <span className="detail-label">Login Attempts:</span>
                          <span className={`detail-value ${notification.userData.loginAttempts > 1 ? 'anomaly-highlight' : ''}`}>
                            {notification.userData.loginAttempts}
                          </span>
                        </div>
                        <div className="detail-row">
                          <span className="detail-label">Behavior Score:</span>
                          <span className="detail-value">{notification.userData.realtimeBehaviorScore}%</span>
                        </div>
                      </>
                    )}
                  </div>
                  
                  <div className="notification-actions">
                    <button 
                      className="action-button view-button"
                      onClick={() => handleViewThreatDetails(notification.userId)}
                    >
                      <FaEye /> View Details
                    </button>
                    <button 
                      className="action-button block-button"
                      onClick={() => handleBlockUser(notification.userId, notification._id)}
                      disabled={processingAction === notification._id || notification.status === 'blocked'}
                    >
                      {processingAction === notification._id ? (
                        <><FaSync className="fa-spin" /> Processing...</>
                      ) : notification.status === 'blocked' ? (
                        <><FaCheck /> User Blocked</>
                      ) : (
                        <><FaUserSlash /> Block User</>
                      )}
                    </button>
                    <button 
                      className="action-button dismiss-button"
                      onClick={() => handleDismissNotification(notification._id)}
                      disabled={processingAction === notification._id}
                    >
                      <FaTrash /> Dismiss
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notification; 