import React, { useState, useEffect, useContext } from 'react';
import '../css/GeoNetworkLayer.css';
import { FaGlobe, FaMapMarkerAlt, FaServer, FaShieldAlt, FaNetworkWired, FaHistory, FaExclamationTriangle, FaCheck, FaLocationArrow } from 'react-icons/fa';
import { createContext } from 'react';


export const GeoNetworkContext = createContext(null);

const GeoNetworkLayer = () => {
  const [ipData, setIpData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [locationPermission, setLocationPermission] = useState('pending');
  const [geoDataExported, setGeoDataExported] = useState(false);

  useEffect(() => {
    fetchIPData();
    requestDeviceLocation();
  }, []);

  useEffect(() => {
    if (ipData && !geoDataExported) {
      const geoNetworkData = {
        ipAddress: ipData.ip,
        deviceLocation: deviceLocation,
        networkProvider: ipData.asn?.name || 'Unknown',
        networkClass: ipData.networkClass,
        asn: ipData.asn?.number || 'Unknown',
        asnType: ipData.asn?.type || 'Unknown',
        city: ipData.details?.city || 'Unknown',
        region: ipData.details?.region || 'Unknown',
        country: ipData.details?.country_name || 'Unknown',
        timezone: ipData.details?.timezone || 'Unknown',
        reputationScore: ipData.reputation?.score || 0,
        riskLevel: ipData.reputation?.risk || 'Unknown',
        velocitySpeed: ipData.velocityData?.velocity || 0,
        distanceFromLastLogin: ipData.velocityData?.distance || 0,
        impossibleTravel: ipData.velocityData?.impossibleTravel || false,
        trustScore: ipData.trustScore?.score || 0,
        trustFactors: ipData.trustScore?.factors || [],
        capturedAt: new Date().toISOString()
      };
      
      console.log("========== STORING GEO NETWORK DATA ==========");
      console.log(geoNetworkData);
      
      localStorage.setItem('itrust360_geo_network_data', JSON.stringify(geoNetworkData));
      setGeoDataExported(true);
      
      console.log("Data stored in localStorage as 'itrust360_geo_network_data'");
    }
  }, [ipData, deviceLocation, geoDataExported]);

  const getGeoNetworkData = () => {
    if (!ipData) return null;
    
    return {
      ipAddress: ipData.ip,
      deviceLocation: deviceLocation,
      networkProvider: ipData.asn?.name || 'Unknown',
      networkClass: ipData.networkClass,
      asn: ipData.asn?.number || 'Unknown',
      asnType: ipData.asn?.type || 'Unknown',
      city: ipData.details?.city || 'Unknown',
      region: ipData.details?.region || 'Unknown',
      country: ipData.details?.country_name || 'Unknown',
      timezone: ipData.details?.timezone || 'Unknown',
      reputationScore: ipData.reputation?.score || 0,
      riskLevel: ipData.reputation?.risk || 'Unknown',
      velocitySpeed: ipData.velocityData?.velocity || 0,
      distanceFromLastLogin: ipData.velocityData?.distance || 0,
      impossibleTravel: ipData.velocityData?.impossibleTravel || false,
      trustScore: ipData.trustScore?.score || 0,
      trustFactors: ipData.trustScore?.factors || [],
      capturedAt: new Date().toISOString()
    };
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const requestDeviceLocation = () => {
    if (!navigator.geolocation) {
      setLocationPermission('unsupported');
      return;
    }

    setLocationPermission('requesting');
    
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log('Got exact device location:', position.coords);
        setDeviceLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
          timestamp: position.timestamp
        });
        setLocationPermission('granted');
      },
      (error) => {
        console.error('Geolocation error:', error);
        alert('Please allow location access for accurate results. This app needs your exact location to function properly.');
        setLocationPermission(
          error.code === error.PERMISSION_DENIED ? 'denied' : 'error'
        );
      },

      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      }
    );
  };

  const fetchIPData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const ip = ipData.ip;
      
      const detailsResponse = await fetch(`https://ipapi.co/${ip}/json/`);
      if (!detailsResponse.ok) {
        throw new Error('Failed to fetch IP details');
      }
      
      const details = await detailsResponse.json();
      
      const reputation = await checkIpReputation(ip);
      
      const velocityData = calculateGeoVelocity(details);
      const trustScore = calculateTrustScore(details, reputation, velocityData);
      
      setIpData({
        ip,
        details,
        reputation,
        velocityData,
        trustScore,
        networkClass: determineNetworkClass(ip),
        cidr: calculateCIDR(ip, details.network),
        asn: {
          number: details.asn,
          name: details.org,
          domain: details.asn_domain || 'Unknown',
          route: details.asn_route || details.network,
          type: determineASNType(details.org)
        }
      });
      
      setAnalysisComplete(true);
    } catch (err) {
      console.error('Error fetching IP data:', err);
      setError('Failed to fetch IP data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const checkIpReputation = async (ip) => {
    
    return new Promise((resolve) => {
      setTimeout(() => {
        const score = Math.floor(Math.random() * 101);
        let risk = 'Low';
        let issues = [];
        
        if (score < 40) {
          risk = 'High';
          issues = ['Suspicious activity detected', 'Associated with malicious traffic'];
        } else if (score < 70) {
          risk = 'Medium';
          issues = ['Potentially suspicious traffic patterns'];
        }
        
        resolve({
          score,
          risk,
          issues,
          lastReported: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString(),
          blacklisted: score < 30 ? true : false,
          whitelisted: score > 90 ? true : false
        });
      }, 500);
    });
  };

  const calculateGeoVelocity = (details) => {
    
    const previousLocations = [
      { lat: details.latitude + 0.1, lon: details.longitude - 0.2, timestamp: Date.now() - 12 * 60 * 60 * 1000 },
      { lat: details.latitude, lon: details.longitude, timestamp: Date.now() - 24 * 60 * 60 * 1000 },
    ];
    
    const currentLocation = { lat: details.latitude, lon: details.longitude, timestamp: Date.now() };
    const velocity = calculateDistanceAndTime(previousLocations[0], currentLocation);
    
    return {
      current: currentLocation,
      previous: previousLocations,
      velocity: velocity.speed,
      impossibleTravel: velocity.speed > 800,
      distance: velocity.distance,
      timeGap: velocity.time
    };
  };

  const calculateDistanceAndTime = (loc1, loc2) => {
    const R = 6371;
    const dLat = deg2rad(loc2.lat - loc1.lat);
    const dLon = deg2rad(loc2.lon - loc1.lon);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(loc1.lat)) * Math.cos(deg2rad(loc2.lat)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const distance = R * c;
    
    const timeDiff = (loc2.timestamp - loc1.timestamp) / (1000 * 60 * 60);
    
    const speed = timeDiff > 0 ? distance / timeDiff : 0;
    
    return {
      distance,
      time: timeDiff,
      speed
    };
  };

  const calculateTrustScore = (details, reputation, velocityData) => {
    let score = 0;
    let maxScore = 0;
    let factors = [];
    
    const repScore = reputation.score * 0.4;
    score += repScore;
    maxScore += 40;
    factors.push({
      name: 'IP Reputation',
      score: repScore,
      maxScore: 40,
      impact: repScore < 20 ? 'negative' : repScore < 30 ? 'neutral' : 'positive'
    });
    
    const velocityScore = velocityData.impossibleTravel ? 0 : 20;
    score += velocityScore;
    maxScore += 20;
    factors.push({
      name: 'Geo-Velocity',
      score: velocityScore,
      maxScore: 20,
      impact: velocityScore < 10 ? 'negative' : 'positive'
    });
    
    let networkScore = 0;
    if (details.org && details.org.toLowerCase().includes('cloud') || 
        details.org && details.org.toLowerCase().includes('host')) {
      networkScore = 5;
    } else if (details.type === 'business') {
      networkScore = 15;
    } else if (details.type === 'isp') {
      networkScore = 10;
    }
    score += networkScore;
    maxScore += 20;
    factors.push({
      name: 'Network Type',
      score: networkScore,
      maxScore: 20,
      impact: networkScore < 10 ? 'negative' : networkScore < 15 ? 'neutral' : 'positive'
    });
    
    let countryScore = 0;
    const lowRiskCountries = ['US', 'CA', 'GB', 'AU', 'NZ', 'JP', 'DE', 'FR', 'IT', 'ES', 'NL'];
    const mediumRiskCountries = ['IN', 'BR', 'MX', 'AR', 'ZA', 'TR', 'SA', 'AE'];
    
    if (lowRiskCountries.includes(details.country_code)) {
      countryScore = 20;
    } else if (mediumRiskCountries.includes(details.country_code)) {
      countryScore = 10;
    } else {
      countryScore = 5;
    }
    score += countryScore;
    maxScore += 20;
    factors.push({
      name: 'Country Risk',
      score: countryScore,
      maxScore: 20,
      impact: countryScore < 10 ? 'negative' : countryScore < 15 ? 'neutral' : 'positive'
    });
    
    const normalizedScore = Math.round((score / maxScore) * 100);
    
    return {
      score: normalizedScore,
      factors,
      trusted: normalizedScore >= 70,
      suspicious: normalizedScore < 40,
      neutral: normalizedScore >= 40 && normalizedScore < 70
    };
  };

  const determineNetworkClass = (ip) => {
    const firstOctet = parseInt(ip.split('.')[0], 10);
    
    if (firstOctet <= 127) return 'Class A';
    if (firstOctet <= 191) return 'Class B';
    if (firstOctet <= 223) return 'Class C';
    if (firstOctet <= 239) return 'Class D (Multicast)';
    return 'Class E (Experimental)';
  };

  const calculateCIDR = (ip, network) => {
    if (!network) return `${ip}/32`;
    
    if (network.includes('/')) return network;
  
    const ipBase = ip.split('.').slice(0, 3).join('.') + '.0';
    return `${ipBase}/24`;
  };

  const determineASNType = (org) => {
    if (!org) return 'Unknown';
    
    const orgLower = org.toLowerCase();
    
    if (orgLower.includes('cloud') || 
        orgLower.includes('host') || 
        orgLower.includes('data center')) {
      return 'Cloud/Hosting Provider';
    }
    
    if (orgLower.includes('university') || 
        orgLower.includes('college') || 
        orgLower.includes('school')) {
      return 'Educational Institution';
    }
    
    if (orgLower.includes('mobile') || 
        orgLower.includes('wireless') || 
        orgLower.includes('cellular')) {
      return 'Mobile Provider';
    }
    
    if (orgLower.includes('vpn') || 
        orgLower.includes('proxy')) {
      return 'VPN/Proxy Service';
    }
    
    return 'ISP/Telecom';
  };

  const formatCoordinate = (coord, type) => {
    if (!coord && coord !== 0) return 'Unknown';
    const degrees = Math.abs(coord);
    const direction = type === 'lat' 
      ? (coord >= 0 ? 'N' : 'S')
      : (coord >= 0 ? 'E' : 'W');
    return `${degrees.toFixed(6)}° ${direction}`;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    
    return distance;
  };

  useEffect(() => {
    if (ipData && deviceLocation) {
      saveGeoDataToServer();
    }
  }, [ipData, deviceLocation]);

  const saveGeoDataToServer = async () => {
    const maxRetries = 3;
    let retryCount = 0;
    let success = false;
    
    while (retryCount < maxRetries && !success) {
      try {
        const token = localStorage.getItem('token');
        
        if (!token) {
          console.error('No authentication token found');
          return;
        }
        
        console.log(`Attempt ${retryCount + 1} to save geo network data...`);
        
        const response = await fetch('/api/sessions/geo-data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            ipAddress: ipData.ip,
            deviceLocation: deviceLocation,
            networkProvider: ipData.asn?.name || 'Unknown',
            networkClass: ipData.networkClass,
            asn: ipData.asn?.number || 'Unknown',
            asnType: ipData.asn?.type || 'Unknown',
            city: ipData.details?.city || 'Unknown',
            region: ipData.details?.region || 'Unknown',
            country: ipData.details?.country_name || 'Unknown',
            timezone: ipData.details?.timezone || 'Unknown',
            reputationScore: ipData.reputation?.score || 0,
            riskLevel: ipData.reputation?.risk || 'Unknown',
            velocitySpeed: ipData.velocityData?.velocity || 0,
            distanceFromLastLogin: ipData.velocityData?.distance || 0,
            impossibleTravel: ipData.velocityData?.impossibleTravel || false,
            trustScore: ipData.trustScore?.score || 0,
            trustFactors: ipData.trustScore?.factors || [],
            capturedAt: new Date().toISOString()
          })
        });
        
        if (response.ok) {
          const result = await response.json();
          console.log('Geo data saved successfully:', result);
          success = true;
        } else if (response.status === 404 && retryCount === maxRetries - 1) {
          console.log('Falling back to sessions/save endpoint...');
          
          const fallbackResponse = await fetch('/api/sessions/save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              isGeoDataOnly: true,
              geoNetwork: {
                ipAddress: ipData.ip,
                deviceLocation: deviceLocation,
                networkProvider: ipData.asn?.name || 'Unknown',
                networkClass: ipData.networkClass,
                asn: ipData.asn?.number || 'Unknown',
                asnType: ipData.asn?.type || 'Unknown',
                city: ipData.details?.city || 'Unknown',
                region: ipData.details?.region || 'Unknown',
                country: ipData.details?.country_name || 'Unknown',
                timezone: ipData.details?.timezone || 'Unknown',
                reputationScore: ipData.reputation?.score || 0,
                riskLevel: ipData.reputation?.risk || 'Unknown',
                velocitySpeed: ipData.velocityData?.velocity || 0,
                distanceFromLastLogin: ipData.velocityData?.distance || 0,
                impossibleTravel: ipData.velocityData?.impossibleTravel || false,
                trustScore: ipData.trustScore?.score || 0,
                trustFactors: ipData.trustScore?.factors || [],
                capturedAt: new Date().toISOString()
              }
            })
          });
          
          if (fallbackResponse.ok) {
            const result = await fallbackResponse.json();
            console.log('Geo data saved through fallback:', result);
            success = true;
          } else {
            throw new Error('Failed to save geo network data through fallback');
          }
        } else {
          throw new Error(`Failed to save geo network data (Status: ${response.status})`);
        }
      } catch (error) {
        console.error(`Attempt ${retryCount + 1} failed:`, error);
        retryCount++;
        if (retryCount < maxRetries && !success) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    }
    
    if (!success) {
      console.error(`Failed to save geo network data after ${maxRetries} attempts`);
    }
  };

  if (loading) {
    return (
      <div className="geo-network-container">
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Analyzing network and geolocation data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="geo-network-container">
        <div className="error-container">
          <FaExclamationTriangle className="error-icon" />
          <p>{error}</p>
          <button className="retry-button" onClick={fetchIPData}>
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!ipData) {
    return null;
  }

  return (
    <GeoNetworkContext.Provider value={{ getGeoNetworkData }}>
      <div className="geo-network-container">
        
        <div className="geo-grid">
          <div className="geo-map-card">
            <div className="card-header">
              <FaMapMarkerAlt className="card-icon" />
              <h3>Location Data</h3>
            </div>
            
            <div className="map-container">
              {!deviceLocation && locationPermission !== 'granted' && (
                <div className="get-exact-location-prompt">
                  <h3>Get Your Exact Location</h3>
                  <p>For accurate location tracking, please share your device's exact location.</p>
                  <button className="permission-button large" onClick={requestDeviceLocation}>
                    <FaLocationArrow className="permission-icon" />
                    Share My Exact Location
                  </button>
                </div>
              )}
              
              {deviceLocation && locationPermission === 'granted' ? (
                <div className="map-with-coordinates">
                  <iframe
                    className="location-map"
                    title="Your Exact Location"
                    src={`https://www.openstreetmap.org/export/embed.html?bbox=${deviceLocation.longitude - 0.01}%2C${deviceLocation.latitude - 0.01}%2C${deviceLocation.longitude + 0.01}%2C${deviceLocation.latitude + 0.01}&layer=mapnik&marker=${deviceLocation.latitude}%2C${deviceLocation.longitude}`}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <div className="map-coordinates-overlay">
                    <div className="coordinates-box">
                      <div className="overlay-coordinate">
                        <span className="overlay-label">Lat:</span>
                        <span className="overlay-value">{deviceLocation.latitude.toFixed(6)}</span>
                      </div>
                      <div className="overlay-coordinate">
                        <span className="overlay-label">Long:</span>
                        <span className="overlay-value">{deviceLocation.longitude.toFixed(6)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ipData?.details?.latitude && ipData?.details?.longitude ? (
                <iframe
                  className="location-map"
                  title="IP-Based Location (Less Accurate)"
                  src={`https://www.openstreetmap.org/export/embed.html?bbox=${ipData.details.longitude - 0.05}%2C${ipData.details.latitude - 0.05}%2C${ipData.details.longitude + 0.05}%2C${ipData.details.latitude + 0.05}&layer=mapnik&marker=${ipData.details.latitude}%2C${ipData.details.longitude}`}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              ) : (
                <div className="map-placeholder">
                  <p>Please allow location access for accurate mapping</p>
                </div>
              )}
            </div>
            
            {deviceLocation && locationPermission === 'granted' && (
              <div className="geo-coordinates exact">
                <div className="exact-location-indicator">
                  <FaLocationArrow className="exact-icon" />
                  <span>Exact Device Location</span>
                </div>
                <div className="coordinate-item">
                  <span className="coordinate-label">Latitude:</span>
                  <span className="coordinate-value exact">
                    {formatCoordinate(deviceLocation.latitude, 'lat')}
                  </span>
                </div>
                <div className="coordinate-item">
                  <span className="coordinate-label">Longitude:</span>
                  <span className="coordinate-value exact">
                    {formatCoordinate(deviceLocation.longitude, 'lon')}
                  </span>
                </div>
                <div className="coordinate-accuracy">
                  <span className="accuracy-label">Accuracy:</span>
                  <span className="accuracy-value">
                    ±{deviceLocation.accuracy ? Math.round(deviceLocation.accuracy) : '?'} meters
                  </span>
                </div>
              </div>
            )}
            
            <div className="geo-details">
              <div className="geo-detail-item">
                <span className="detail-label">City:</span>
                <span className="detail-value">{ipData.details.city || 'Unknown'}</span>
              </div>
              <div className="geo-detail-item">
                <span className="detail-label">Region:</span>
                <span className="detail-value">{ipData.details.region || 'Unknown'}</span>
              </div>
              <div className="geo-detail-item">
                <span className="detail-label">Country:</span>
                <span className="detail-value">{ipData.details.country_name || 'Unknown'}</span>
              </div>
              <div className="geo-detail-item">
                <span className="detail-label">Timezone:</span>
                <span className="detail-value">{ipData.details.timezone || 'Unknown'}</span>
              </div>
            </div>
          </div>
          
          <div className="network-info-card">
            <div className="card-header">
              <FaNetworkWired className="card-icon" />
              <h3>Network Information</h3>
            </div>
            
            <div className="network-details">
              <div className="network-detail-item">
                <span className="detail-label">IP Address:</span>
                <span className="detail-value ip-value">{ipData.ip}</span>
              </div>
              <div className="network-detail-item">
                <span className="detail-label">Provider:</span>
                <span className="detail-value">{ipData.asn.name || 'Unknown'}</span>
              </div>
              <div className="network-detail-item">
                <span className="detail-label">Network Class:</span>
                <span className="detail-value">{ipData.networkClass}</span>
              </div>
              <div className="network-detail-item">
                <span className="detail-label">CIDR:</span>
                <span className="detail-value">{ipData.cidr}</span>
              </div>
              <div className="network-detail-item">
                <span className="detail-label">ASN:</span>
                <span className="detail-value">{ipData.asn.number || 'Unknown'}</span>
              </div>
              <div className="network-detail-item">
                <span className="detail-label">ASN Type:</span>
                <span className="detail-value">{ipData.asn.type}</span>
              </div>
              <div className="network-detail-item">
                <span className="detail-label">ASN Domain:</span>
                <span className="detail-value">{ipData.asn.domain}</span>
              </div>
              <div className="network-detail-item">
                <span className="detail-label">Network Route:</span>
                <span className="detail-value">{ipData.asn.route}</span>
              </div>
            </div>
            
            <div className="reputation-container">
              
              
              <div className="reputation-details">
                <div className="reputation-detail-item">
                  <span className="detail-label">Risk Level:</span>
                  <span className={`detail-value risk-${ipData.reputation.risk.toLowerCase()}`}>
                    {ipData.reputation.risk}
                  </span>
                </div>
                
                <div className="reputation-detail-item">
                  <span className="detail-label">Last Reported:</span>
                  <span className="detail-value">
                    {new Date(ipData.reputation.lastReported).toLocaleDateString()}
                  </span>
                </div>
                
                <div className="reputation-detail-item">
                  <span className="detail-label">Blacklisted:</span>
                  <span className="detail-value">
                    {ipData.reputation.blacklisted ? 'Yes' : 'No'}
                  </span>
                </div>
              </div>
            </div>
            
            {ipData.reputation.issues.length > 0 && (
              <div className="reputation-issues">
                <h4>Reputation Issues</h4>
                <ul className="issues-list">
                  {ipData.reputation.issues.map((issue, index) => (
                    <li key={index} className="issue-item">
                      <FaExclamationTriangle className="issue-icon" />
                      <span className="issue-text">{issue}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          
          <div className="velocity-card">
            <div className="card-header">
              <FaHistory className="card-icon" />
              <h3>Geo-Velocity Analysis</h3>
            </div>
            
            <div className="velocity-details">
              <div className="velocity-metric">
                <div className="velocity-metric-value">
                  {Math.round(ipData.velocityData.velocity) || 0}
                  <span className="velocity-unit">km/h</span>
                </div>
                <div className="velocity-metric-label">Calculated Travel Speed</div>
              </div>
              
              <div className="velocity-metric">
                <div className="velocity-metric-value">
                  {Math.round(ipData.velocityData.distance) || 0}
                  <span className="velocity-unit">km</span>
                </div>
                <div className="velocity-metric-label">Distance from Last Login</div>
              </div>
              
              <div className="velocity-metric">
                <div className="velocity-metric-value">
                  {Math.round(ipData.velocityData.timeGap * 10) / 10 || 0}
                  <span className="velocity-unit">hrs</span>
                </div>
                <div className="velocity-metric-label">Time Since Last Login</div>
              </div>
            </div>
            
            {ipData.velocityData.impossibleTravel && (
              <div className="velocity-warning">
                <FaExclamationTriangle className="warning-icon" />
                <div className="warning-text">
                  <h4>Impossible Travel Detected</h4>
                  <p>The user appears to be moving at a speed that indicates 
                     potentially suspicious activity or account sharing.</p>
                </div>
              </div>
            )}
            
            <div className="velocity-locations">
              <h4>Recent Locations</h4>
              <div className="locations-timeline">
                {[ipData.velocityData.current, ...ipData.velocityData.previous].map((location, index) => (
                  <div key={index} className={`location-point ${index === 0 ? 'current' : ''}`}>
                    <div className="location-marker"></div>
                    <div className="location-info">
                      <div className="location-time">
                        {new Date(location.timestamp).toLocaleString()}
                      </div>
                      <div className="location-coordinates">
                        {formatCoordinate(location.lat, 'lat')}, {formatCoordinate(location.lon, 'lon')}
                      </div>
                      {index === 0 && <div className="location-current-tag">Current</div>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          <div className="trust-score-card">
            
            
            <div className="trust-factors">
              <h4>Analysis Factors</h4>
              
              {ipData.trustScore.factors.map((factor, index) => (
                <div key={index} className="trust-factor-item">
                  <div className="factor-header">
                    <span className="factor-name">{factor.name}</span>
                    <span className={`factor-impact impact-${factor.impact}`}>
                      {factor.impact.charAt(0).toUpperCase() + factor.impact.slice(1)}
                    </span>
                  </div>
                  <div className="factor-bar">
                    <div 
                      className={`factor-fill impact-${factor.impact}`} 
                      style={{ width: `${(factor.score / factor.maxScore) * 100}%` }}
                    ></div>
                  </div>
                  <div className="factor-score">
                    {Math.round(factor.score)} / {factor.maxScore}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </GeoNetworkContext.Provider>
  );
};

export default GeoNetworkLayer; 