import React, { useState, useEffect } from 'react';
import { FaShieldAlt, FaExclamationTriangle, FaUser, FaSpinner, FaSync, FaFilter, FaChartBar, FaTimes, FaLock } from 'react-icons/fa';
import '../css/RiskScore.css';

const RiskScore = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userScores, setUserScores] = useState([]);
  const [filteredScores, setFilteredScores] = useState([]);
  const [recalculating, setRecalculating] = useState(false);
  const [filters, setFilters] = useState({
    riskLevel: 'all',
    minScore: '',
    maxScore: '',
    showMfaEnabled: true,
    showMfaDisabled: true
  });
  const [showFilters, setShowFilters] = useState(false);
  const [savingReview, setSavingReview] = useState(false);

  useEffect(() => {
    fetchRiskScores();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [userScores, filters]);

  const fetchRiskScores = async () => {
    try {
      setLoading(true);
      console.log('Fetching risk scores from API...');
      const response = await fetch('/api/risk-scores');
      console.log('API response status:', response.status);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Received data:', data);
      setUserScores(data);
      setFilteredScores(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching risk scores:', err);
      setError(`Failed to load risk score data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRecalculate = async () => {
    try {
      setRecalculating(true);
      const response = await fetch('/api/risk-scores/recalculate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      await fetchRiskScores();
    } catch (err) {
      console.error('Error recalculating risk scores:', err);
      setError('Failed to recalculate risk scores. Please try again later.');
    } finally {
      setRecalculating(false);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const resetFilters = () => {
    setFilters({
      riskLevel: 'all',
      minScore: '',
      maxScore: '',
      showMfaEnabled: true,
      showMfaDisabled: true
    });
  };

  const applyFilters = () => {
    let result = [...userScores];
    
    if (filters.riskLevel !== 'all') {
      result = result.filter(user => user.riskLevel === filters.riskLevel);
    }
    
    if (filters.minScore !== '') {
      const minScore = parseFloat(filters.minScore);
      result = result.filter(user => user.finalRiskScore >= minScore);
    }
    
    if (filters.maxScore !== '') {
      const maxScore = parseFloat(filters.maxScore);
      result = result.filter(user => user.finalRiskScore <= maxScore);
    }
    
    const mfaFilterApplied = !(filters.showMfaEnabled && filters.showMfaDisabled);
    if (mfaFilterApplied) {
      if (filters.showMfaEnabled && !filters.showMfaDisabled) {
        result = result.filter(user => user.mfaEnabled);
      } else if (!filters.showMfaEnabled && filters.showMfaDisabled) {
        result = result.filter(user => !user.mfaEnabled);
      }
    }
    
    setFilteredScores(result);
  };

  const getRiskClass = (score) => {
    if (score >= 80) return 'critical';
    if (score >= 60) return 'high';
    if (score >= 40) return 'medium';
    return 'low';
  };

  const handleAdminReview = async (user) => {
    try {
      setSavingReview(true);
      
      const reviewData = {
        userId: user.userId,
        deviceSessionScore: user.deviceSessionScore,
        behavioralScore: user.behavioralScore,
        historicalRiskScore: user.historicalRiskScore,
        systemLogsScore: user.systemLogsScore,
        finalRiskScore: user.finalRiskScore,
        riskLevel: user.riskLevel,
        accessLevel: user.accessLevel,
        reviewStatus: 'pending',
        reviewDate: new Date().toISOString(),
        createdAt: new Date(),
        updatedAt: null
      };
      
      console.log('Submitting review data:', reviewData);
      
      const response = await fetch('/api/admin-review', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reviewData)
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      
      const result = await response.json();
      console.log('Database save result:', result);
      
      alert(`User "${user.userId}" has been submitted for admin review.`);
    } catch (error) {
      console.error('Error submitting review:', error);
      alert(`Failed to submit for admin review: ${error.message}`);
    } finally {
      setSavingReview(false);
    }
  };

  const renderAccessLevelCell = (user) => {
    if (user.accessLevel === "Admin Review") {
      return (
        <td className="access-level">
          <div className="access-level-with-action">
            <span>{user.accessLevel}</span>
            <button 
              className="admin-review-button"
              onClick={() => handleAdminReview(user)}
              disabled={savingReview}
            >
              {savingReview ? <FaSpinner className="spinner-small" /> : <FaLock />} Submit for Review
            </button>
          </div>
        </td>
      );
    }
    
    return <td className="access-level">{user.accessLevel}</td>;
  };

  if (loading) {
    return (
      <div className="risk-score-container loading">
        <FaSpinner className="spinner" />
        <p>Loading risk assessment data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="risk-score-container error">
        <FaExclamationTriangle />
        <p>{error}</p>
        <button onClick={fetchRiskScores} className="retry-button">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="risk-score-container">
      <div className="risk-score-header">
        <div className="title-section">
          <h2><FaShieldAlt /> User Risk Assessment</h2>
          <p>Security risk analysis based on user behavior patterns</p>
        </div>
        <div className="header-actions">
          <button 
            className="filter-button"
            onClick={() => setShowFilters(!showFilters)}
          >
            <FaFilter /> {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>
          <button 
            onClick={handleRecalculate} 
            className="recalculate-button"
            disabled={recalculating}
          >
            {recalculating ? <FaSpinner className="spinner" /> : <FaSync />}
            {recalculating ? 'Recalculating...' : 'Recalculate Scores'}
          </button>
        </div>
      </div>
      
      {showFilters && (
        <div className="filter-panel">
          <div className="filter-panel-header">
            <h3>Filter Results</h3>
            <button className="reset-filters" onClick={resetFilters}>
              <FaTimes /> Reset
            </button>
          </div>
          <div className="filter-controls">
            <div className="filter-group">
              <label>Risk Level</label>
              <select 
                name="riskLevel" 
                value={filters.riskLevel} 
                onChange={handleFilterChange}
              >
                <option value="all">All Levels</option>
                <option value="critical">Critical</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
            </div>
            
            <div className="filter-group">
              <label>Min Score</label>
              <input 
                type="number" 
                name="minScore" 
                placeholder="0" 
                min="0" 
                max="100" 
                value={filters.minScore} 
                onChange={handleFilterChange}
              />
            </div>
            
            <div className="filter-group">
              <label>Max Score</label>
              <input 
                type="number" 
                name="maxScore" 
                placeholder="100" 
                min="0" 
                max="100" 
                value={filters.maxScore} 
                onChange={handleFilterChange}
              />
            </div>
            
            <div className="filter-group checkbox-group">
              <label>MFA Status</label>
              <div className="checkbox-controls">
                <label className="checkbox-label">
                  <input 
                    type="checkbox" 
                    name="showMfaEnabled" 
                    checked={filters.showMfaEnabled} 
                    onChange={handleFilterChange} 
                  />
                  Enabled
                </label>
                <label className="checkbox-label">
                  <input 
                    type="checkbox" 
                    name="showMfaDisabled" 
                    checked={filters.showMfaDisabled} 
                    onChange={handleFilterChange} 
                  />
                  Disabled
                </label>
              </div>
            </div>
          </div>
          <div className="results-summary">
            Showing {filteredScores.length} of {userScores.length} users
          </div>
        </div>
      )}
      
      <div className="risk-score-summary">
        <div className="summary-card">
          <h3>High Risk Users</h3>
          <div className="summary-value high-risk">
            {userScores.filter(user => user.finalRiskScore >= 60).length}
          </div>
        </div>
        <div className="summary-card">
          <h3>Medium Risk Users</h3>
          <div className="summary-value medium-risk">
            {userScores.filter(user => user.finalRiskScore >= 40 && user.finalRiskScore < 60).length}
          </div>
        </div>
        <div className="summary-card">
          <h3>Low Risk Users</h3>
          <div className="summary-value low-risk">
            {userScores.filter(user => user.finalRiskScore < 40).length}
          </div>
        </div>
        <div className="summary-card">
          <h3>Total Users</h3>
          <div className="summary-value">
            {userScores.length}
          </div>
        </div>
      </div>
      
      <div className="risk-score-table-container">
        <table className="risk-score-table">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Device & Session</th>
              <th>Behavioral</th>
              <th>Historical Risk</th>
              <th>System Logs</th>
              <th>Final Risk Score</th>
              <th>Risk Level</th>
              <th>Access Level</th>
            </tr>
          </thead>
          <tbody>
            {filteredScores.map(user => (
              <tr key={user.userId}>
                <td className="user-id">{user.userId.substring(0, 8)}...</td>
                <td className="numeric-score">{user.deviceSessionScore.toFixed(1)}</td>
                <td className="numeric-score">{user.behavioralScore.toFixed(1)}</td>
                <td className="numeric-score">{user.historicalRiskScore.toFixed(1)}</td>
                <td className="numeric-score">{user.systemLogsScore.toFixed(1)}</td>
                <td>
                  <div className="final-score-container">
                    <div className="score-bar-container">
                      <div 
                        className={`score-bar ${getRiskClass(user.finalRiskScore)}`}
                        style={{width: `${user.finalRiskScore}%`}}
                      ></div>
                    </div>
                    <div className={`final-score-value ${getRiskClass(user.finalRiskScore)}`}>
                      {user.finalRiskScore.toFixed(1)}
                    </div>
                  </div>
                </td>
                <td>
                  <span className={`risk-badge ${user.riskLevel}`}>
                    {user.riskLevel.charAt(0).toUpperCase() + user.riskLevel.slice(1)}
                  </span>
                </td>
                {renderAccessLevelCell(user)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RiskScore; 