import React, { useState, useEffect } from 'react';
import { FaSearch, FaFilter, FaDownload, FaUserCog, FaDatabase } from 'react-icons/fa';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');
  const [useMockData, setUseMockData] = useState(false);
  
  // Mock data for testing when API is unavailable
  const mockUsers = [
    {
      _id: "mock1",
      username: "admin",
      email: "admin@itrust360.com",
      firstName: "Admin",
      lastName: "User",
      role: "admin",
      lastLoginTime: "2025-03-17T14:46:09.994+00:00",
      failedLoginCount: 3,
      accessFrequency: "daily",
      loginConsistency: 90,
      mfaStatus: 1
    },
    {
      _id: "mock2",
      username: "john.doe",
      email: "john.doe@example.com",
      firstName: "John",
      lastName: "Doe",
      role: "user",
      lastLoginTime: "2025-03-15T09:22:45.234+00:00",
      failedLoginCount: 0,
      accessFrequency: "weekly",
      loginConsistency: 75,
      mfaStatus: 1
    },
    {
      _id: "mock3",
      username: "jane.smith",
      email: "jane.smith@example.com",
      firstName: "Jane",
      lastName: "Smith",
      role: "manager",
      lastLoginTime: "2025-03-16T11:30:22.546+00:00",
      failedLoginCount: 1,
      accessFrequency: "daily",
      loginConsistency: 85,
      mfaStatus: 0
    }
  ];

  const styles = `
    .users-container {
      padding: 20px;
    }

    .users-header {
      margin-bottom: 20px;
    }

    .users-header h2 {
      font-size: 24px;
      display: flex;
      align-items: center;
    }

    .header-icon {
      margin-right: 10px;
    }

    .search-filter-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-wrap: wrap;
    }

    .search-container {
      display: flex;
      gap: 10px;
      flex: 1;
    }

    .search-input-container {
      position: relative;
      max-width: 300px;
    }

    .search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #666;
    }

    .search-input {
      padding: 8px 10px 8px 35px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
    }

    .filter-container {
      max-width: 200px;
    }

    .role-filter {
      padding: 8px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
    }

    .export-btn {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 8px 16px;
      background-color: #2a69ac;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    .export-btn:hover {
      background-color: #1e4e8c;
    }

    .users-table-container {
      width: 100%;
      overflow-x: auto;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .users-table {
      width: 100%;
      border-collapse: collapse;
    }

    .users-table th,
    .users-table td {
      padding: 12px 16px;
      text-align: left;
      border-bottom: 1px solid #e2e8f0;
    }

    .users-table th {
      background-color: #f8f9fa;
      font-weight: 600;
    }

    .users-table tr:last-child td {
      border-bottom: none;
    }

    .role-badge {
      display: inline-block;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .role-badge.admin {
      background-color: #fed7d7;
      color: #e53e3e;
    }

    .role-badge.manager {
      background-color: #feebc8;
      color: #dd6b20;
    }

    .role-badge.user {
      background-color: #c6f6d5;
      color: #38a169;
    }

    .mfa-badge {
      display: inline-block;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 600;
    }

    .mfa-badge.enabled {
      background-color: #c6f6d5;
      color: #38a169;
    }

    .mfa-badge.disabled {
      background-color: #fed7d7;
      color: #e53e3e;
    }

    .no-results {
      text-align: center;
      padding: 24px;
      color: #666;
    }

    .loading-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top: 4px solid #3498db;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
      margin-bottom: 10px;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .error-alert {
      background-color: #fed7d7;
      color: #e53e3e;
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 20px;
    }

    .mock-data-banner {
      background-color: #f8d7da;
      color: #721c24;
      padding: 8px 12px;
      margin-bottom: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .use-mock-btn {
      background-color: #1e88e5;
      color: white;
      border: none;
      padding: 6px 12px;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 10px;
    }
    
    .use-mock-btn:hover {
      background-color: #1565c0;
    }
  `;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        
        // Use mock data if selected
        if (useMockData) {
          setUsers(mockUsers);
          setLoading(false);
          return;
        }
        
        // Use the public endpoint instead of the authenticated one
        const response = await fetch('/api/users/public');
        
        if (!response.ok) {
          throw new Error(`API error: ${response.status} ${response.statusText}`);
        }
        
        const data = await response.json();
        console.log("Fetched users:", data);
        setUsers(data);
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error('Error fetching users:', err);
        setError(`Error: ${err.message || 'Failed to load users'}. You can use mock data for testing.`);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [useMockData]); // Add useMockData as dependency to reload when it changes

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getRoleBadgeClass = (role) => {
    switch (role?.toLowerCase()) {
      case 'admin':
        return 'role-badge admin';
      case 'manager':
        return 'role-badge manager';
      case 'user':
        return 'role-badge user';
      default:
        return 'role-badge';
    }
  };

  // Filter users based on search and role filter
  const filteredUsers = users.filter(user => {
    // Apply search filter to username, email, first and last name
    const matchesSearch = searchQuery === '' || 
      (user.username && user.username.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      ((user.firstName && user.lastName) && 
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()));
    
    // Apply role filter
    const matchesRole = roleFilter === 'all' || 
      (user.role && user.role.toLowerCase() === roleFilter.toLowerCase());
    
    return matchesSearch && matchesRole;
  });

  const exportUserData = () => {
    const csvData = [
      ['Username', 'Email', 'Full Name', 'Role', 'Last Login', 'MFA Status', 'Login Consistency'],
      ...filteredUsers.map(user => [
        user.username,
        user.email,
        `${user.firstName || ''} ${user.lastName || ''}`,
        user.role,
        user.lastLoginTime,
        user.mfaStatus ? 'Enabled' : 'Disabled',
        `${user.loginConsistency || 0}%`
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'users_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading users...</p>
      </div>
    );
  }

  return (
    <>
      <style>{styles}</style>
      <div className="users-container">
        <div className="users-header">
          <h2><FaUserCog className="header-icon" /> User Management</h2>
        </div>
        
        {/* Show error with option to use mock data */}
        {error && (
          <div className="mock-data-banner">
            <div>{error}</div>
            <button 
              className="use-mock-btn" 
              onClick={() => setUseMockData(true)}
            >
              <FaDatabase style={{ marginRight: '5px' }} /> Use Mock Data
            </button>
          </div>
        )}
        
        {useMockData && (
          <div className="mock-data-banner">
            <div>Using mock data for demonstration. API connection unavailable.</div>
            <button 
              className="use-mock-btn" 
              onClick={() => setUseMockData(false)}
            >
              Try API Again
            </button>
          </div>
        )}
        
        <div className="search-filter-container">
          <div className="search-container">
            <div className="search-input-container">
              <FaSearch className="search-icon" />
              <input 
                type="text" 
                placeholder="Search users..." 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
            
            <div className="filter-container">
              <select 
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
                className="role-filter"
              >
                <option value="all">All Roles</option>
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
                <option value="user">User</option>
              </select>
            </div>
          </div>
          
          <button 
            className="export-btn"
            onClick={exportUserData}
          >
            <FaDownload /> Export
          </button>
        </div>
        
        <div className="users-table-container">
          <table className="users-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th>Last Login</th>
                <th>MFA Status</th>
                <th>Login Consistency</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <tr key={user._id}>
                    <td>{`${user.firstName || ''} ${user.lastName || ''}`}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>
                      <span className={getRoleBadgeClass(user.role)}>
                        {user.role}
                      </span>
                    </td>
                    <td>{formatDate(user.lastLoginTime)}</td>
                    <td>
                      <span className={`mfa-badge ${user.mfaStatus ? "enabled" : "disabled"}`}>
                        {user.mfaStatus ? "Enabled" : "Disabled"}
                      </span>
                    </td>
                    <td>{user.loginConsistency ? `${user.loginConsistency}%` : 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="no-results">
                    No users match your search criteria
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Users; 