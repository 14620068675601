import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Dashboard.css';
import { FaUsers, FaChartLine, FaClipboardList, FaCog, FaBell, FaSignOutAlt, FaBars, FaUniversity, FaFingerprint, FaClock, FaGlobe, FaUserSecret, FaBrain, FaSpinner, FaUserLock, FaShieldAlt } from 'react-icons/fa';
import Banking from './Banking';
import DeviceSession from './DeviceSession';
import SessionAndMetadata from './SessionAndMetadata';
import GeoNetworkLayer from './GeoNetworkLayer';
import Behavioral from './Behavioral';
import InsiderThreat from './InsiderThreat';
import Notification from './Notification';
import BehaviorMonitoring from './BehaviorMonitoring';
import PredictiveAnalytics from './PredictiveAnalytics';
import Users from './Users';
import RiskScore from './risk_score';
import AdminReviews from './AdminReviews';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [savingSession, setSavingSession] = useState(false);
  const [deviceData, setDeviceData] = useState({});
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    collectDeviceData();
  }, []);

  const collectDeviceData = async () => {
    try {
      const screenResolution = `${window.screen.width}×${window.screen.height}`;
      const colorDepth = window.screen.colorDepth;
      
      const userAgent = navigator.userAgent;
      const browserData = detectBrowser(userAgent);
      
      const osData = detectOS(userAgent);
      
      const language = navigator.language || navigator.userLanguage;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezoneOffset = new Date().getTimezoneOffset();
      
      const doNotTrack = navigator.doNotTrack || 
                         window.doNotTrack || 
                         navigator.msDoNotTrack || 'Not available';
      
      const deviceMemory = navigator.deviceMemory || 'Not available';
      
      const hardwareConcurrency = navigator.hardwareConcurrency || 'Not available';
      
      const data = {
        screenResolution,
        colorDepth,
        browser: browserData.browser,
        browserVersion: browserData.version,
        os: osData.os,
        osVersion: osData.version,
        language,
        timezone,
        timezoneOffset,
        doNotTrack,
        deviceMemory,
        hardwareConcurrency,
        touchSupport: 'ontouchstart' in window,
        cookiesEnabled: navigator.cookieEnabled,
        platform: navigator.platform,
      };
      
      setDeviceData(data);
      
      let type = 'desktop';
      if (data.os === 'iOS' || data.os === 'Android') {
        if (parseInt(data.screenResolution.split('×')[0]) < 768) {
          type = 'mobile';
        } else {
          type = 'tablet';
        }
      }
      
      setDeviceType(type);
      
    } catch (error) {
      console.error('Error collecting device data:', error);
    }
  };
  
  const detectBrowser = (userAgent) => {
    const browsers = [
      { name: 'Chrome', regex: /Chrome\/([0-9.]+)/ },
      { name: 'Firefox', regex: /Firefox\/([0-9.]+)/ },
      { name: 'Safari', regex: /Safari\/([0-9.]+).*Version\/([0-9.]+)/ },
      { name: 'Edge', regex: /Edg(e|)\/([0-9.]+)/ },
      { name: 'Opera', regex: /OPR\/([0-9.]+)/ },
      { name: 'IE', regex: /Trident\/.*rv:([0-9.]+)/ }
    ];
    
    for (const browser of browsers) {
      const match = userAgent.match(browser.regex);
      if (match) {
        return { 
          browser: browser.name, 
          version: browser.name === 'Safari' ? match[2] : match[1] 
        };
      }
    }
    
    return { browser: 'Unknown', version: 'Unknown' };
  };
  
  const detectOS = (userAgent) => {
    const operatingSystems = [
      { name: 'Windows', regex: /Windows NT ([0-9.]+)/ },
      { name: 'macOS', regex: /Mac OS X ([0-9._]+)/ },
      { name: 'iOS', regex: /iPhone OS ([0-9_]+)/ },
      { name: 'Android', regex: /Android ([0-9.]+)/ },
      { name: 'Linux', regex: /Linux/ }
    ];
    
    for (const os of operatingSystems) {
      const match = userAgent.match(os.regex);
      if (match) {
        let version = match[1] || 'Unknown';
        version = version.replace(/_/g, '.');
        return { os: os.name, version };
      }
    }
    
    return { os: 'Unknown', version: 'Unknown' };
  };

  const handleLogout = async () => {
    try {
      setSavingSession(true);
      
      const token = localStorage.getItem('token');
      
      if (!token) {
        console.error('No authentication token found');
        return;
      }
      
      const sessionData = {
        deviceType: deviceType || 'unknown',
        operatingSystem: deviceData.os || 'unknown',
        browser: deviceData.browser || 'unknown',
        screenResolution: deviceData.screenResolution || 'unknown',
        colorDepth: deviceData.colorDepth ? `${deviceData.colorDepth}-bit` : 'unknown',
        deviceMemory: deviceData.deviceMemory || 'unknown',
        cpuCores: deviceData.hardwareConcurrency || 'unknown',
        platform: deviceData.platform || 'unknown',
        touchSupport: deviceData.touchSupport ? 'Yes' : 'No',
        language: deviceData.language || 'unknown',
        timezone: deviceData.timezone || 'unknown',
        timezoneOffset: deviceData.timezoneOffset ? `${deviceData.timezoneOffset} minutes` : 'unknown',
        cookiesEnabled: deviceData.cookiesEnabled ? 'Yes' : 'No',
        doNotTrack: deviceData.doNotTrack || 'unknown',
      };
      
      const geoNetworkDataString = localStorage.getItem('itrust360_geo_network_data');
      if (geoNetworkDataString) {
        try {
          const geoNetworkData = JSON.parse(geoNetworkDataString);
          sessionData.geoNetwork = geoNetworkData;
          console.log("========== SENDING GEO DATA IN LOGOUT ==========");
          console.log(JSON.stringify(sessionData.geoNetwork, null, 2));
        } catch (e) {
          console.error('Error parsing geo network data:', e);
        }
      }

      const response = await fetch('/api/sessions/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(sessionData)
      });
      
      if (!response.ok) {
        throw new Error('Failed to save session data');
      }
      
      if (sessionData.geoNetwork) {
        try {
          console.log('Geo network data will be saved with the session data');
          
          console.log('Saved geo network data:', {
            ipAddress: sessionData.geoNetwork.ipAddress,
            location: sessionData.geoNetwork.deviceLocation ? 
              `${sessionData.geoNetwork.deviceLocation.latitude}, ${sessionData.geoNetwork.deviceLocation.longitude}` : 
              'unknown',
            networkProvider: sessionData.geoNetwork.networkProvider,
            trustScore: sessionData.geoNetwork.trustScore
          });
        } catch (error) {
          console.error('Error processing geo network data:', error);
        }
      }
      
      localStorage.removeItem('itrust360_behavior_tracking');
      localStorage.removeItem('itrust360_behavior_data');
      localStorage.removeItem('itrust360_geo_network_data');
      
      localStorage.removeItem('token');
      
      navigate('/');
      
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setSavingSession(false);
    }
  };

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderActiveView = () => {
    switch (activeTab) {
      case 'banking':
        return <Banking />;
      case 'devices':
        return <DeviceSession />;
      case 'sessions':
        return <SessionAndMetadata />;
      case 'geolocation':
        return <GeoNetworkLayer />;
      case 'behavioral':
        return <Behavioral />;
      case 'insider-threat':
        return <InsiderThreat />;
      case 'notifications':
        return <Notification />;
      case 'behavior-monitoring':
        return <BehaviorMonitoring />;
      case 'predictive-analytics':
        return <PredictiveAnalytics />;
      case 'risk-score':
        return <RiskScore />;
      case 'users':
        return <Users />;
      case 'dashboard':
        return (
          <>
            <div className="welcome-banner">
              <h1>Welcome to iTrust360</h1>
              <p>Your comprehensive security and trust management platform</p>
            </div>
            
            <div className="dashboard-summary">
              <div className="summary-card">
                <h3>Security Status</h3>
                <div className="status-indicator positive">Protected</div>
                <p>All systems functioning normally</p>
              </div>
              
              <div className="summary-card">
                <h3>Pending Reviews</h3>
                <div className="status-count">{Math.floor(Math.random() * 5)}</div>
                <p>Admin reviews awaiting action</p>
              </div>

            </div>
          </>
        );
      case 'admin-reviews':
        return <AdminReviews />;
      default:
        return <div className="content-placeholder">
          <h2>{getTabTitle(activeTab)} - Coming Soon</h2>
          <p>This feature is currently under development.</p>
        </div>;
    }
  };

  const getTabTitle = (tab) => {
    const titles = {
      'dashboard': 'Dashboard',
      'banking': 'Banking',
      'devices': 'Device Sessions',
      'sessions': 'Session Monitoring',
      'geolocation': 'Geo Network',
      'behavioral': 'Behavioral Analysis',
      'insider-threat': 'Insider Threat',
      'notifications': 'Notifications',
      'behavior-monitoring': 'Behavior Monitoring',
      'predictive-analytics': 'Predictive Analytics',
      'risk-score': 'Risk Assessment',
      'users': 'Users',
      'reports': 'Reports',
      'settings': 'Settings',
      'admin-reviews': 'Admin Reviews'
    };
    
    return titles[tab] || tab.charAt(0).toUpperCase() + tab.slice(1);
  };

  return (
    <div className="dashboard-container">
      <div className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-header">
          <h2>iTrust360</h2>
          <button className="toggle-sidebar" onClick={toggleSidebar}>
            <FaBars />
          </button>
        </div>
        <div className="sidebar-content">
          <ul className="sidebar-menu">
            <li className={activeTab === 'dashboard' ? 'active' : ''} onClick={() => handleTabClick('dashboard')}>
              <FaChartLine />
              <span>Dashboard</span>
            </li>
            
            <li className="menu-section-title">Security & Analytics</li>
            <li className={activeTab === 'behavioral' ? 'active' : ''} onClick={() => handleTabClick('behavioral')}>
              <FaFingerprint />
              <span>Behavioral Analysis</span>
            </li>
            <li className={activeTab === 'risk-score' ? 'active' : ''} onClick={() => handleTabClick('risk-score')}>
              <FaShieldAlt />
              <span>Risk Assessment</span>
            </li>
            <li className={activeTab === 'insider-threat' ? 'active' : ''} onClick={() => handleTabClick('insider-threat')}>
              <FaUserSecret />
              <span>Insider Threat</span>
            </li>
            <li className={activeTab === 'predictive-analytics' ? 'active' : ''} onClick={() => handleTabClick('predictive-analytics')}>
              <FaBrain />
              <span>Predictive Analytics</span>
            </li>
            
            <li className="menu-section-title">Monitoring</li>
            
            <li className={activeTab === 'sessions' ? 'active' : ''} onClick={() => handleTabClick('sessions')}>
              <FaClock />
              <span>Session Monitoring</span>
            </li>
            <li className={activeTab === 'behavior-monitoring' ? 'active' : ''} onClick={() => handleTabClick('behavior-monitoring')}>
              <FaFingerprint />
              <span>Behavior Monitoring</span>
            </li>
            <li className={activeTab === 'devices' ? 'active' : ''} onClick={() => handleTabClick('devices')}>
              <FaFingerprint />
              <span>Device Sessions</span>
            </li>
            <li className={activeTab === 'geolocation' ? 'active' : ''} onClick={() => handleTabClick('geolocation')}>
              <FaGlobe />
              <span>Geo Network</span>
            </li>
            
            <li className="menu-section-title">Administration</li>
            
            <li className={activeTab === 'notifications' ? 'active' : ''} onClick={() => handleTabClick('notifications')}>
              <FaBell />
              <span>Notifications</span>
            </li>
            <li className={activeTab === 'admin-reviews' ? 'active' : ''} onClick={() => handleTabClick('admin-reviews')}>
              <FaClipboardList />
              <span>Admin Reviews</span>
            </li>
          </ul>
        </div>
        <div className="sidebar-footer">
          <button onClick={handleLogout} className="logout-sidebar-btn" disabled={savingSession}>
            {savingSession ? (
              <>
                <FaSpinner className="spinner-icon" />
                <span>Saving...</span>
              </>
            ) : (
              <>
                <FaSignOutAlt />
                <span>Logout</span>
              </>
            )}
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className={`main-content ${sidebarCollapsed ? 'expanded' : ''}`}>
        <header className="dashboard-header">
          <div className="header-left">
            {sidebarCollapsed && (
              <button className="toggle-sidebar" onClick={toggleSidebar}>
                <FaBars />
              </button>
            )}
            <h1>Admin Dashboard{activeTab !== 'dashboard' ? ` - ${getTabTitle(activeTab)}` : ''}</h1>
          </div>
          <div className="header-right">
            <button className="logout-button" onClick={handleLogout} disabled={savingSession}>
              {savingSession ? (
                <>
                  <FaSpinner className="spinner-icon" /> Saving...
                </>
              ) : (
                'Logout'
              )}
            </button>
          </div>
        </header>
        
        <div className="dashboard-content">
          {renderActiveView()}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard; 